/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BaseCustomer } from '../models/base-customer';
import { Company } from '../models/company';
import { Customers } from '../models/customers';
import { Mortgage } from '../models/mortgage';
import { Person } from '../models/person';

@Injectable({
  providedIn: 'root',
})
export class CustomersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomers
   */
  static readonly GetCustomersPath = '/api/v1/Customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Plain$Response(params?: {
    count?: number;
    page?: number;
    types?: Array<'Company' | 'Person'>;
    name?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomersPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('types', params.types, {});
      rb.query('name', params.name, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Plain(params?: {
    count?: number;
    page?: number;
    types?: Array<'Company' | 'Person'>;
    name?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getCustomers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: {
    count?: number;
    page?: number;
    types?: Array<'Company' | 'Person'>;
    name?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomersPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('types', params.types, {});
      rb.query('name', params.name, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: {
    count?: number;
    page?: number;
    types?: Array<'Company' | 'Person'>;
    name?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getCustomers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * Path part for operation getCustomerById
   */
  static readonly GetCustomerByIdPath = '/api/v1/Customers/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById$Plain$Response(params: {
    customerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<(BaseCustomer | Company | Person)>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerByIdPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(BaseCustomer | Company | Person)>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById$Plain(params: {
    customerId: string;
  },
  context?: HttpContext

): Observable<(BaseCustomer | Company | Person)> {

    return this.getCustomerById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<(BaseCustomer | Company | Person)>) => r.body as (BaseCustomer | Company | Person))
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById$Response(params: {
    customerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<(BaseCustomer | Company | Person)>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerByIdPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(BaseCustomer | Company | Person)>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById(params: {
    customerId: string;
  },
  context?: HttpContext

): Observable<(BaseCustomer | Company | Person)> {

    return this.getCustomerById$Response(params,context).pipe(
      map((r: StrictHttpResponse<(BaseCustomer | Company | Person)>) => r.body as (BaseCustomer | Company | Person))
    );
  }

  /**
   * Path part for operation getCustomerQuestionnaires
   */
  static readonly GetCustomerQuestionnairesPath = '/api/v1/Customers/{id}/Questionnaires';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerQuestionnaires$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerQuestionnaires$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Mortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerQuestionnairesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Mortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerQuestionnaires$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerQuestionnaires$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Mortgage>> {

    return this.getCustomerQuestionnaires$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Mortgage>>) => r.body as Array<Mortgage>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerQuestionnaires()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerQuestionnaires$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Mortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerQuestionnairesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Mortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerQuestionnaires$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerQuestionnaires(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Mortgage>> {

    return this.getCustomerQuestionnaires$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Mortgage>>) => r.body as Array<Mortgage>)
    );
  }

}
