import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ToastType, Toast } from '@app/core/toaster.service';

@Component({
  selector: 'vf-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent {

  toastType = ToastType;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public toast: Toast) { }

}
