import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../toaster.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error, request)));
  }

  /**
   * Handle the given http error
   *
   * @param response error response
   */
  private errorHandler(response: HttpErrorResponse, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      console.log(response);
    } else {
      // Log the error to a dedicated logger if the client is running in production mode?
    }

    if (response.status === 400 && (request.method === 'POST' || request.method === 'PUT' || request.method === 'PATCH')) {
      this.toasterService.error(this.translateService.instant('No valid input'));
    } else if (response.status === 401) {
      this.toasterService.error(this.translateService.instant('The user is not authorized'));
    } else if (response.status === 403) {
      this.toasterService.error(this.translateService.instant('The user has not sufficient permissions for this action'));
    } else if (response.status === 500) {
      this.toasterService.error(this.translateService.instant('Server error'));
    }

    return throwError(response);
  }
}
