<div class="flex flex-1">
  <mat-sidenav-container  class="flex flex-1">
    <mat-sidenav #sidenav mode="over" ngClass.gt-sm="has-border">
      <nav>
        <mat-list>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/home" routerLinkActive="active" (click)="sidenav.close()">
            <span matListItemTitle translate>Home</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/applications" routerLinkActive="active" (click)="sidenav.close()">
            <span matListItemTitle translate>Applications</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/controls" routerLinkActive="active" (click)="sidenav.close()">
            <span matListItemTitle translate>Controls</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/about" routerLinkActive="active" (click)="sidenav.close()">
            <span matListItemTitle translate>About</span>
          </a>
          <mat-divider></mat-divider>
        </mat-list>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content>
      <vf-header [sidenav]="sidenav"></vf-header>
      <div class="container max-w-none">
        <div *ngIf="loading === true" class="loader loader-page">
          <mat-spinner diameter="32"></mat-spinner>
        </div>
        <router-outlet *ngIf="loading === false"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>