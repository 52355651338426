<mat-toolbar color="primary" [ngClass]="'mat-elevation-z6 navbar ' + toolbarClass">
  <div class="flex md:hidden justify-start items-center">
    <button class="menu-button" mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="title">{{title}}</span>
  </div>
  <div class="hidden md:flex justify-start items-center gap-2 nav-buttons">
    <a class="logo" routerLink="/">
      <img src="assets/unite-logo-white.svg" alt="Unite" />
    </a>
    <button mat-button routerLink="/applications" routerLinkActive="active">
      <span translate>Applications</span>
    </button>
    <button mat-button routerLink="/controls" routerLinkActive="active">
      <span translate>Controls</span>
    </button>
  </div>
  <span class="flex-1"></span>
  <span class="hidden md:flex mat-small mr-8 mt-1">{{actor | uppercase}}</span>
  <button class="hidden md:flex" mat-icon-button routerLink="/about" routerLinkActive="active">
    <mat-icon>info</mat-icon>
  </button>
  <button mat-icon-button [matMenuTriggerFor]="languageMenu">
    <mat-icon>language</mat-icon>
  </button>
  <mat-menu #languageMenu="matMenu">
    <button mat-menu-item [disabled]="currentLanguage === 'nl-NL'" (click)="setLanguage('nl-NL')">
      <span translate>Dutch</span>
    </button>
    <button mat-menu-item [disabled]="currentLanguage === 'en-US'" (click)="setLanguage('en-US')">
      <span translate>English</span>
    </button>
  </mat-menu>
  <button mat-icon-button [matMenuTriggerFor]="personMenu">
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #personMenu="matMenu">
    <button [disabled]="true" mat-menu-item routerLink="/account">
      <span translate>Account</span>&nbsp;({{surename}})
    </button>
    <button mat-menu-item  routerLink="/settings">
      <span translate>Settings</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <span translate>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>