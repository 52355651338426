/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RequiredDocumentType } from '../models/required-document-type';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDocumentTypes
   */
  static readonly GetDocumentTypesPath = '/api/v1/DocumentTypes';

  /**
   * Returns a list of available document types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RequiredDocumentType>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequiredDocumentType>>;
      })
    );
  }

  /**
   * Returns a list of available document types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<RequiredDocumentType>> {

    return this.getDocumentTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RequiredDocumentType>>) => r.body as Array<RequiredDocumentType>)
    );
  }

  /**
   * Returns a list of available document types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RequiredDocumentType>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequiredDocumentType>>;
      })
    );
  }

  /**
   * Returns a list of available document types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes(params?: {
  },
  context?: HttpContext

): Observable<Array<RequiredDocumentType>> {

    return this.getDocumentTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RequiredDocumentType>>) => r.body as Array<RequiredDocumentType>)
    );
  }

}
