/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CadastralUserSettings } from '../models/cadastral-user-settings';
import { ChamberOfCommerceSettings } from '../models/chamber-of-commerce-settings';
import { JsonPatchDocument } from '../models/json-patch-document';
import { Organization } from '../models/organization';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOrganizationById
   */
  static readonly GetOrganizationByIdPath = '/api/v1/Organizations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationById$Plain$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetOrganizationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationById$Plain(params: {
    id: number;
  },
  context?: HttpContext

): Observable<Organization> {

    return this.getOrganizationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationById$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetOrganizationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationById(params: {
    id: number;
  },
  context?: HttpContext

): Observable<Organization> {

    return this.getOrganizationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation updateOrganization
   */
  static readonly UpdateOrganizationPath = '/api/v1/Organizations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOrganization$Plain$Response(params: {
    id: number;
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.UpdateOrganizationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOrganization$Plain(params: {
    id: number;
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.updateOrganization$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOrganization$Response(params: {
    id: number;
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.UpdateOrganizationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOrganization(params: {
    id: number;
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.updateOrganization$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation patchOrganization
   */
  static readonly PatchOrganizationPath = '/api/v1/Organizations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrganization$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchOrganization$Plain$Response(params: {
    id: number;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.PatchOrganizationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrganization$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchOrganization$Plain(params: {
    id: number;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Organization> {

    return this.patchOrganization$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrganization()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchOrganization$Response(params: {
    id: number;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.PatchOrganizationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrganization$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchOrganization(params: {
    id: number;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Organization> {

    return this.patchOrganization$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation getUsers
   */
  static readonly GetUsersPath = '/api/v1/Organizations/{id}/Users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Plain$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetUsersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Plain(params: {
    id: number;
  },
  context?: HttpContext

): Observable<Array<User>> {

    return this.getUsers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetUsersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params: {
    id: number;
  },
  context?: HttpContext

): Observable<Array<User>> {

    return this.getUsers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

  /**
   * Path part for operation addUser
   */
  static readonly AddUserPath = '/api/v1/Organizations/{id}/Users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUser$Plain$Response(params: {
    id: number;
    body?: User
  },
  context?: HttpContext

): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.AddUserPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUser$Plain(params: {
    id: number;
    body?: User
  },
  context?: HttpContext

): Observable<User> {

    return this.addUser$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUser$Response(params: {
    id: number;
    body?: User
  },
  context?: HttpContext

): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.AddUserPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUser(params: {
    id: number;
    body?: User
  },
  context?: HttpContext

): Observable<User> {

    return this.addUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation getUserById
   */
  static readonly GetUserByIdPath = '/api/v1/Organizations/{id}/Users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById$Plain$Response(params: {
    id: number;
    userId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetUserByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById$Plain(params: {
    id: number;
    userId: string;
  },
  context?: HttpContext

): Observable<User> {

    return this.getUserById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById$Response(params: {
    id: number;
    userId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetUserByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById(params: {
    id: number;
    userId: string;
  },
  context?: HttpContext

): Observable<User> {

    return this.getUserById$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/api/v1/Organizations/{id}/Users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Plain$Response(params: {
    id: number;
    userId: string;
    body?: User
  },
  context?: HttpContext

): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.UpdateUserPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Plain(params: {
    id: number;
    userId: string;
    body?: User
  },
  context?: HttpContext

): Observable<User> {

    return this.updateUser$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Response(params: {
    id: number;
    userId: string;
    body?: User
  },
  context?: HttpContext

): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.UpdateUserPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser(params: {
    id: number;
    userId: string;
    body?: User
  },
  context?: HttpContext

): Observable<User> {

    return this.updateUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation deleteUserById
   */
  static readonly DeleteUserByIdPath = '/api/v1/Organizations/{id}/Users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserById$Response(params: {
    id: number;
    userId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.DeleteUserByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserById(params: {
    id: number;
    userId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteUserById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCadastralUserSettings
   */
  static readonly GetCadastralUserSettingsPath = '/api/v1/Organizations/{id}/CadastralUserSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCadastralUserSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCadastralUserSettings$Plain$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralUserSettings>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetCadastralUserSettingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralUserSettings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCadastralUserSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCadastralUserSettings$Plain(params: {
    id: number;
  },
  context?: HttpContext

): Observable<CadastralUserSettings> {

    return this.getCadastralUserSettings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralUserSettings>) => r.body as CadastralUserSettings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCadastralUserSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCadastralUserSettings$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralUserSettings>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetCadastralUserSettingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralUserSettings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCadastralUserSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCadastralUserSettings(params: {
    id: number;
  },
  context?: HttpContext

): Observable<CadastralUserSettings> {

    return this.getCadastralUserSettings$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralUserSettings>) => r.body as CadastralUserSettings)
    );
  }

  /**
   * Path part for operation updateCadastralUserSettings
   */
  static readonly UpdateCadastralUserSettingsPath = '/api/v1/Organizations/{id}/CadastralUserSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCadastralUserSettings$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCadastralUserSettings$Plain$Response(params: {
    id: number;
    body?: CadastralUserSettings
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralUserSettings>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.UpdateCadastralUserSettingsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralUserSettings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCadastralUserSettings$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCadastralUserSettings$Plain(params: {
    id: number;
    body?: CadastralUserSettings
  },
  context?: HttpContext

): Observable<CadastralUserSettings> {

    return this.updateCadastralUserSettings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralUserSettings>) => r.body as CadastralUserSettings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCadastralUserSettings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCadastralUserSettings$Response(params: {
    id: number;
    body?: CadastralUserSettings
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralUserSettings>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.UpdateCadastralUserSettingsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralUserSettings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCadastralUserSettings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCadastralUserSettings(params: {
    id: number;
    body?: CadastralUserSettings
  },
  context?: HttpContext

): Observable<CadastralUserSettings> {

    return this.updateCadastralUserSettings$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralUserSettings>) => r.body as CadastralUserSettings)
    );
  }

  /**
   * Path part for operation getChamberOfCommerceSettings
   */
  static readonly GetChamberOfCommerceSettingsPath = '/api/v1/Organizations/{id}/ChamberOfCommerceSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChamberOfCommerceSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChamberOfCommerceSettings$Plain$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ChamberOfCommerceSettings>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetChamberOfCommerceSettingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChamberOfCommerceSettings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChamberOfCommerceSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChamberOfCommerceSettings$Plain(params: {
    id: number;
  },
  context?: HttpContext

): Observable<ChamberOfCommerceSettings> {

    return this.getChamberOfCommerceSettings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ChamberOfCommerceSettings>) => r.body as ChamberOfCommerceSettings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChamberOfCommerceSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChamberOfCommerceSettings$Response(params: {
    id: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ChamberOfCommerceSettings>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.GetChamberOfCommerceSettingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChamberOfCommerceSettings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChamberOfCommerceSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChamberOfCommerceSettings(params: {
    id: number;
  },
  context?: HttpContext

): Observable<ChamberOfCommerceSettings> {

    return this.getChamberOfCommerceSettings$Response(params,context).pipe(
      map((r: StrictHttpResponse<ChamberOfCommerceSettings>) => r.body as ChamberOfCommerceSettings)
    );
  }

  /**
   * Path part for operation updateChamberOfCommerceSettings
   */
  static readonly UpdateChamberOfCommerceSettingsPath = '/api/v1/Organizations/{id}/ChamberOfCommerceSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateChamberOfCommerceSettings$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateChamberOfCommerceSettings$Plain$Response(params: {
    id: number;
    body?: ChamberOfCommerceSettings
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ChamberOfCommerceSettings>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.UpdateChamberOfCommerceSettingsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChamberOfCommerceSettings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateChamberOfCommerceSettings$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateChamberOfCommerceSettings$Plain(params: {
    id: number;
    body?: ChamberOfCommerceSettings
  },
  context?: HttpContext

): Observable<ChamberOfCommerceSettings> {

    return this.updateChamberOfCommerceSettings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ChamberOfCommerceSettings>) => r.body as ChamberOfCommerceSettings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateChamberOfCommerceSettings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateChamberOfCommerceSettings$Response(params: {
    id: number;
    body?: ChamberOfCommerceSettings
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ChamberOfCommerceSettings>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationsService.UpdateChamberOfCommerceSettingsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChamberOfCommerceSettings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateChamberOfCommerceSettings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateChamberOfCommerceSettings(params: {
    id: number;
    body?: ChamberOfCommerceSettings
  },
  context?: HttpContext

): Observable<ChamberOfCommerceSettings> {

    return this.updateChamberOfCommerceSettings$Response(params,context).pipe(
      map((r: StrictHttpResponse<ChamberOfCommerceSettings>) => r.body as ChamberOfCommerceSettings)
    );
  }

}
