export const environment = {
  app_name: 'Voogd Finance_dev',
  production: false,
  serverUrl: 'https://octopus-dev-api.azurewebsites.net',
  updateNotificationServerUrl: 'wss://octopus-dev-websocket.azurewebsites.net',
  defaultLanguage: 'nl-NL',
  supportedLanguages: ['en-US', 'nl-NL'],
  auth: {
    clientID: 'PoASJ1QPwnW0JtSUS5mk30nSd0AoOHZw',
    domain: 'voogdfinance.eu.auth0.com',
    scope: 'openid profile',
    baseUri: 'https://octopus-client-dev.unite.nl',
    redirectUri: 'https://octopus-client-dev.unite.nl/callback',
    returnUri: 'https://octopus-client-dev.unite.nl/login',
    audience: 'https://octopus-api.unite.nl',
    namespace: 'https://unite.nl/'
  }
};
