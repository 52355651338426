import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { I18nService } from './core/i18n.service';
import { Logger } from './core/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Toast, ToasterService, ToastType } from './core/toaster.service';
import { ToasterComponent } from './shared/toaster/toaster.component';
import { AuthenticationService } from './core/authentication/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'vf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  toasts: Toast[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private toasterService: ToasterService,
    private matSnackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {

  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Check authentication
    this.authenticationService.localAuthSetup();

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle('Unite - ' + this.translateService.instant(title));
        }
      });

    // Toasts
    this.toasterService.getToast().subscribe((toast: Toast) => {
      if (!toast) { // Clear all toasts when an empty toast is received
        this.toasts = [];
        return;
      }

      this.toasts.push(toast);

      if (toast.type === ToastType.success) {
        this.matSnackBar.openFromComponent(ToasterComponent, { data: toast, duration: 4000, panelClass: toast.type });
      } else {
        this.matSnackBar.openFromComponent(ToasterComponent, { data: toast, duration: 5000, panelClass: toast.type });
      }
    });

    log.debug('app iniitalized');
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
