/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Buildings } from '../models/buildings';
import { Customers } from '../models/customers';
import { Guarantor } from '../models/guarantor';
import { Guarantors } from '../models/guarantors';
import { JsonPatchDocument } from '../models/json-patch-document';
import { Limits } from '../models/limits';
import { LoanPart } from '../models/loan-part';
import { LoanParts } from '../models/loan-parts';
import { Mortgage } from '../models/mortgage';
import { MortgageDocument } from '../models/mortgage-document';
import { MortgageDocuments } from '../models/mortgage-documents';
import { Mortgages } from '../models/mortgages';
import { Terms } from '../models/terms';

@Injectable({
  providedIn: 'root',
})
export class MortgagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMortgages
   */
  static readonly GetMortgagesPath = '/api/v1/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgages$Plain$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Request' | 'Active' | 'Expired'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgages>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgagesPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgages>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgages$Plain(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Request' | 'Active' | 'Expired'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Mortgages> {

    return this.getMortgages$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgages>) => r.body as Mortgages)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgages$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Request' | 'Active' | 'Expired'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgages>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgagesPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgages>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgages(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Request' | 'Active' | 'Expired'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Mortgages> {

    return this.getMortgages$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgages>) => r.body as Mortgages)
    );
  }

  /**
   * Path part for operation getMortgageById
   */
  static readonly GetMortgageByIdPath = '/api/v1/Mortgages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.getMortgageById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.getMortgageById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * Path part for operation patchMortgage
   */
  static readonly PatchMortgagePath = '/api/v1/Mortgages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMortgage$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgage$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PatchMortgagePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMortgage$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgage$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.patchMortgage$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMortgage()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgage$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PatchMortgagePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMortgage$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgage(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.patchMortgage$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * Path part for operation getMortgageBorrowers
   */
  static readonly GetMortgageBorrowersPath = '/api/v1/Mortgages/{id}/Borrowers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBorrowers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowers$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBorrowersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBorrowers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowers$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getMortgageBorrowers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBorrowers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowers$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBorrowersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBorrowers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowers(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getMortgageBorrowers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * Path part for operation getMortgageBuildings
   */
  static readonly GetMortgageBuildingsPath = '/api/v1/Mortgages/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getMortgageBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getMortgageBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * Path part for operation createClosingNote
   */
  static readonly CreateClosingNotePath = '/api/v1/Mortgages/{id}/ClosingNote';

  /**
   * Generates new closing note PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createClosingNote$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createClosingNote$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateClosingNotePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new closing note PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createClosingNote$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createClosingNote$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createClosingNote$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Generates new closing note PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createClosingNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  createClosingNote$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateClosingNotePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new closing note PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createClosingNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createClosingNote(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createClosingNote$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Path part for operation createCollateralList
   */
  static readonly CreateCollateralListPath = '/api/v1/Mortgages/{id}/CollateralList';

  /**
   * Generates new collateral list PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCollateralList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCollateralList$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateCollateralListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new collateral list PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCollateralList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCollateralList$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createCollateralList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Generates new collateral list PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCollateralList()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCollateralList$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateCollateralListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new collateral list PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCollateralList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createCollateralList(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createCollateralList$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Path part for operation getMortgageDocumentsById
   */
  static readonly GetMortgageDocumentsByIdPath = '/api/v1/Mortgages/{id}/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageDocumentsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageDocumentsById$Plain$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocuments>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageDocumentsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocuments>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageDocumentsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageDocumentsById$Plain(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<MortgageDocuments> {

    return this.getMortgageDocumentsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocuments>) => r.body as MortgageDocuments)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageDocumentsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageDocumentsById$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocuments>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageDocumentsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocuments>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageDocumentsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageDocumentsById(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<MortgageDocuments> {

    return this.getMortgageDocumentsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocuments>) => r.body as MortgageDocuments)
    );
  }

  /**
   * Path part for operation getMortgageGuarantors
   */
  static readonly GetMortgageGuarantorsPath = '/api/v1/Mortgages/{id}/Guarantors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageGuarantors$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageGuarantors$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Guarantors>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageGuarantorsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Guarantors>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageGuarantors$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageGuarantors$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Guarantors> {

    return this.getMortgageGuarantors$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Guarantors>) => r.body as Guarantors)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageGuarantors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageGuarantors$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Guarantors>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageGuarantorsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Guarantors>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageGuarantors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageGuarantors(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Guarantors> {

    return this.getMortgageGuarantors$Response(params,context).pipe(
      map((r: StrictHttpResponse<Guarantors>) => r.body as Guarantors)
    );
  }

  /**
   * Path part for operation updateMortgageGuarantor
   */
  static readonly UpdateMortgageGuarantorPath = '/api/v1/Mortgages/{id}/Guarantors/{guarantorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMortgageGuarantor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgageGuarantor$Response(params: {
    id: string;
    guarantorId: string;
    body?: Guarantor
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.UpdateMortgageGuarantorPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('guarantorId', params.guarantorId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMortgageGuarantor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgageGuarantor(params: {
    id: string;
    guarantorId: string;
    body?: Guarantor
  },
  context?: HttpContext

): Observable<void> {

    return this.updateMortgageGuarantor$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createBailDeed
   */
  static readonly CreateBailDeedPath = '/api/v1/Mortgages/{id}/Guarantors/{guarantorId}/BailDeed';

  /**
   * Generates new Bail Deed PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBailDeed$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBailDeed$Plain$Response(params: {
    id: string;
    guarantorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateBailDeedPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('guarantorId', params.guarantorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new Bail Deed PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBailDeed$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBailDeed$Plain(params: {
    id: string;
    guarantorId: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createBailDeed$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Generates new Bail Deed PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBailDeed()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBailDeed$Response(params: {
    id: string;
    guarantorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateBailDeedPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('guarantorId', params.guarantorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new Bail Deed PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBailDeed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBailDeed(params: {
    id: string;
    guarantorId: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createBailDeed$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Path part for operation createBailLetter
   */
  static readonly CreateBailLetterPath = '/api/v1/Mortgages/{id}/Guarantors/{guarantorId}/BailLetter';

  /**
   * Generates new Bail Letter PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBailLetter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBailLetter$Plain$Response(params: {
    id: string;
    guarantorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateBailLetterPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('guarantorId', params.guarantorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new Bail Letter PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBailLetter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBailLetter$Plain(params: {
    id: string;
    guarantorId: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createBailLetter$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Generates new Bail Letter PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBailLetter()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBailLetter$Response(params: {
    id: string;
    guarantorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateBailLetterPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('guarantorId', params.guarantorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new Bail Letter PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBailLetter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createBailLetter(params: {
    id: string;
    guarantorId: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createBailLetter$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Path part for operation getMortgageLimits
   */
  static readonly GetMortgageLimitsPath = '/api/v1/Mortgages/{id}/Limits';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLimits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLimits$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Limits>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLimitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Limits>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLimits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLimits$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Limits> {

    return this.getMortgageLimits$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Limits>) => r.body as Limits)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLimits$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Limits>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLimitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Limits>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLimits(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Limits> {

    return this.getMortgageLimits$Response(params,context).pipe(
      map((r: StrictHttpResponse<Limits>) => r.body as Limits)
    );
  }

  /**
   * Path part for operation createLoanAgreement
   */
  static readonly CreateLoanAgreementPath = '/api/v1/Mortgages/{id}/LoanAgreement';

  /**
   * Generates new loan agreement PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLoanAgreement$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createLoanAgreement$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateLoanAgreementPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new loan agreement PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createLoanAgreement$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createLoanAgreement$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createLoanAgreement$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Generates new loan agreement PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLoanAgreement()` instead.
   *
   * This method doesn't expect any request body.
   */
  createLoanAgreement$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateLoanAgreementPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new loan agreement PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createLoanAgreement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createLoanAgreement(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createLoanAgreement$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Path part for operation getMortgageLoanPartById
   */
  static readonly GetMortgageLoanPartByIdPath = '/api/v1/Mortgages/{id}/LoanParts/{loanPartId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanPartById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartById$Plain$Response(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanPartById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartById$Plain(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.getMortgageLoanPartById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanPartById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartById$Response(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanPartById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartById(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.getMortgageLoanPartById$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * Path part for operation patchMortgageLoanPart
   */
  static readonly PatchMortgageLoanPartPath = '/api/v1/Mortgages/{id}/LoanParts/{loanPartId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMortgageLoanPart$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgageLoanPart$Plain$Response(params: {
    id: string;
    loanPartId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PatchMortgageLoanPartPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMortgageLoanPart$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgageLoanPart$Plain(params: {
    id: string;
    loanPartId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.patchMortgageLoanPart$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMortgageLoanPart()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgageLoanPart$Response(params: {
    id: string;
    loanPartId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PatchMortgageLoanPartPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMortgageLoanPart$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgageLoanPart(params: {
    id: string;
    loanPartId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.patchMortgageLoanPart$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * Path part for operation getMortgageLoanPartBuildingsById
   */
  static readonly GetMortgageLoanPartBuildingsByIdPath = '/api/v1/Mortgages/{id}/LoanParts/{loanPartId}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanPartBuildingsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartBuildingsById$Plain$Response(params: {
    id: string;
    loanPartId: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartBuildingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanPartBuildingsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartBuildingsById$Plain(params: {
    id: string;
    loanPartId: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getMortgageLoanPartBuildingsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanPartBuildingsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartBuildingsById$Response(params: {
    id: string;
    loanPartId: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartBuildingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanPartBuildingsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartBuildingsById(params: {
    id: string;
    loanPartId: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getMortgageLoanPartBuildingsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * Path part for operation getMortgageLoanParts
   */
  static readonly GetMortgageLoanPartsPath = '/api/v1/Mortgages/{id}/LoanParts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanParts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanParts>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanParts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanParts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<LoanParts> {

    return this.getMortgageLoanParts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanParts>) => r.body as LoanParts)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanParts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanParts>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanParts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanParts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<LoanParts> {

    return this.getMortgageLoanParts$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanParts>) => r.body as LoanParts)
    );
  }

  /**
   * Path part for operation createSepaForm
   */
  static readonly CreateSepaFormPath = '/api/v1/Mortgages/{id}/SepaForm';

  /**
   * Generates new SEPA Form PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSepaForm$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSepaForm$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateSepaFormPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new SEPA Form PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSepaForm$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSepaForm$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createSepaForm$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Generates new SEPA Form PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSepaForm()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSepaForm$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateSepaFormPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new SEPA Form PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSepaForm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSepaForm(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createSepaForm$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Path part for operation getMortgageTerms
   */
  static readonly GetMortgageTermsPath = '/api/v1/Mortgages/{id}/Terms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageTerms$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageTerms$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Terms>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageTermsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Terms>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageTerms$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageTerms$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Terms> {

    return this.getMortgageTerms$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Terms>) => r.body as Terms)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageTerms$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Terms>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageTermsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Terms>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageTerms(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Terms> {

    return this.getMortgageTerms$Response(params,context).pipe(
      map((r: StrictHttpResponse<Terms>) => r.body as Terms)
    );
  }

  /**
   * Path part for operation createTermOverview
   */
  static readonly CreateTermOverviewPath = '/api/v1/Mortgages/{id}/TermOverview';

  /**
   * Generates new term overview PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTermOverview$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTermOverview$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateTermOverviewPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new term overview PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTermOverview$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTermOverview$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createTermOverview$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Generates new term overview PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTermOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTermOverview$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateTermOverviewPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new term overview PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTermOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTermOverview(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createTermOverview$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Path part for operation createTermSheet
   */
  static readonly CreateTermSheetPath = '/api/v1/Mortgages/{id}/TermSheet';

  /**
   * Generates new term sheet PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTermSheet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTermSheet$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateTermSheetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new term sheet PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTermSheet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTermSheet$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createTermSheet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

  /**
   * Generates new term sheet PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTermSheet()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTermSheet$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.CreateTermSheetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocument>;
      })
    );
  }

  /**
   * Generates new term sheet PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTermSheet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTermSheet(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocument> {

    return this.createTermSheet$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocument>) => r.body as MortgageDocument)
    );
  }

}
