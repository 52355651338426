import { Injectable } from '@angular/core';
import { createAuth0Client, Auth0Client, Auth0ClientOptions, GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { User, Organization, Lender } from '../api/models';
import { I18nService } from '../i18n.service';
import { ControlsService } from '../api/services';

@Injectable()
export class AuthenticationService {

  // User information
  user: User;
  userId: any = null;
  roles: string[];

  // Organization
  organization: Organization;
  organizationId: any = null;

  // Lender
  lender: Lender = null;
  lenderId: any = null;

  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client(<Auth0ClientOptions>{
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      authorizationParams: {
        redirect_uri: environment.auth.redirectUri,
        audience: environment.auth.audience,
        scope: environment.auth.scope
      }
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(private router: Router, private i18nService: I18nService) { }


  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser())),
      tap(user => {
        this.setUserProfile(user);
        this.userProfileSubject$.next(user);
      })
    );
  }

  getTokenSilently$(options?: any): Observable<GetTokenSilentlyVerboseResponse> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      // If authenticated, response will be user object
      // If not authenticated, response will be 'false'
      this.loggedIn = !!response;
    });
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        appState: { target: redirectPath },
        authorizationParams: {
          redirect_uri: environment.auth.redirectUri,
          ui_locales: this.i18nService.language.substring(0, 2)
        }
      });
    });
  }

  handleAuthCallback() {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // Have client, now call method to handle auth callback redirect
      tap(cbRes => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() =>
        // Redirect callback complete; get user and login status
        combineLatest([
          this.getUser$(),
          this.isAuthenticated$
        ])
      )
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, loggedIn]) => {

      // Redirect to target route after callback processing
      this.router.navigateByUrl(targetRoute);

    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        clientId: environment.auth.clientID,
        logoutParams: {
          returnTo: environment.auth.returnUri
        }
      });
    });
  }

  /**
   * Set the user info from Auth0
   *
   * @param user User info from Auth0
   */
  private setUserProfile(user: any) {
    if (user[environment.auth.namespace] != null) {
      this.organizationId = String(user[environment.auth.namespace].organizationId).toLowerCase();
      this.userId = String(user[environment.auth.namespace].userId).toLowerCase();
      this.lenderId = user[environment.auth.namespace].lenderId ? String(user[environment.auth.namespace].lenderId).toLowerCase() : null;
      this.roles = user[environment.auth.namespace].roles;
    } else {
      console.error('Could not find the Auth0 namespace!');
      this.logout();
    }
  }
}
