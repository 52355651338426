import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from './shell/shell.service';

const routes: Routes = [
  Shell.childRoutes(
    [
      { path: '', redirectTo: 'applications', pathMatch: 'full' },
      { path: 'home', loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule) },
      { path: 'about', loadChildren: () => import('src/app/about/about.module').then(m => m.AboutModule) },
      { path: 'settings', loadChildren: () => import('src/app/settings/settings.module').then(m => m.SettingsModule) },
      { path: 'controls', loadChildren: () => import('src/app/controls/controls.module').then(m => m.ControlsModule) },
      { path: 'applications', loadChildren: () => import('src/app/applications/applications.module').then(m => m.ApplicationsModule) },
    ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: 'applications', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
