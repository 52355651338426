import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiModule } from './api/api.module';
import { I18nService } from './i18n.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
// import { RouteReusableStrategy } from './route-reusable-strategy';
import { PaginatorI18n } from './translation-providers/paginator-i18n';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ToasterService } from './toaster.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthenticationService } from './authentication/authentication.service';
import { environment } from '@env/environment';
import { RouteReusableStrategy } from './route-reusable-strategy';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    RouterModule,
    ApiModule.forRoot({rootUrl: environment.serverUrl}),
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    I18nService,
    ToasterService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
