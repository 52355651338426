/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Building } from '../models/building';
import { BuildingValuation } from '../models/building-valuation';
import { CadastralDesignation } from '../models/cadastral-designation';
import { CadastralDesignations } from '../models/cadastral-designations';
import { JsonPatchDocument } from '../models/json-patch-document';
import { Renovation } from '../models/renovation';
import { RentalContract } from '../models/rental-contract';
import { Room } from '../models/room';

@Injectable({
  providedIn: 'root',
})
export class BuildingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCadastralDesignations
   */
  static readonly GetCadastralDesignationsPath = '/api/v1/Buildings/{id}/CadastralDesignations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCadastralDesignations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCadastralDesignations$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralDesignations>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetCadastralDesignationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralDesignations>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCadastralDesignations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCadastralDesignations$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<CadastralDesignations> {

    return this.getCadastralDesignations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralDesignations>) => r.body as CadastralDesignations)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCadastralDesignations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCadastralDesignations$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralDesignations>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetCadastralDesignationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralDesignations>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCadastralDesignations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCadastralDesignations(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<CadastralDesignations> {

    return this.getCadastralDesignations$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralDesignations>) => r.body as CadastralDesignations)
    );
  }

  /**
   * Path part for operation addCadastralDesignation
   */
  static readonly AddCadastralDesignationPath = '/api/v1/Buildings/{id}/CadastralDesignations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCadastralDesignation$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCadastralDesignation$Plain$Response(params: {
    id: string;
    body?: CadastralDesignation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralDesignation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddCadastralDesignationPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralDesignation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCadastralDesignation$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCadastralDesignation$Plain(params: {
    id: string;
    body?: CadastralDesignation
  },
  context?: HttpContext

): Observable<CadastralDesignation> {

    return this.addCadastralDesignation$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralDesignation>) => r.body as CadastralDesignation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCadastralDesignation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCadastralDesignation$Response(params: {
    id: string;
    body?: CadastralDesignation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralDesignation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddCadastralDesignationPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralDesignation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCadastralDesignation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCadastralDesignation(params: {
    id: string;
    body?: CadastralDesignation
  },
  context?: HttpContext

): Observable<CadastralDesignation> {

    return this.addCadastralDesignation$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralDesignation>) => r.body as CadastralDesignation)
    );
  }

  /**
   * Path part for operation deleteCadastralDesignation
   */
  static readonly DeleteCadastralDesignationPath = '/api/v1/Buildings/{id}/CadastralDesignations/{cadastralDesignationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCadastralDesignation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCadastralDesignation$Response(params: {
    id: string;
    cadastralDesignationId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteCadastralDesignationPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('cadastralDesignationId', params.cadastralDesignationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCadastralDesignation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCadastralDesignation(params: {
    id: string;
    cadastralDesignationId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCadastralDesignation$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchCadastralDesignation
   */
  static readonly PatchCadastralDesignationPath = '/api/v1/Buildings/{id}/CadastralDesignations/{cadastralDesignationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCadastralDesignation$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCadastralDesignation$Plain$Response(params: {
    id: string;
    cadastralDesignationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralDesignation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchCadastralDesignationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('cadastralDesignationId', params.cadastralDesignationId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralDesignation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCadastralDesignation$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCadastralDesignation$Plain(params: {
    id: string;
    cadastralDesignationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<CadastralDesignation> {

    return this.patchCadastralDesignation$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralDesignation>) => r.body as CadastralDesignation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCadastralDesignation()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCadastralDesignation$Response(params: {
    id: string;
    cadastralDesignationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CadastralDesignation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchCadastralDesignationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('cadastralDesignationId', params.cadastralDesignationId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CadastralDesignation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCadastralDesignation$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCadastralDesignation(params: {
    id: string;
    cadastralDesignationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<CadastralDesignation> {

    return this.patchCadastralDesignation$Response(params,context).pipe(
      map((r: StrictHttpResponse<CadastralDesignation>) => r.body as CadastralDesignation)
    );
  }

  /**
   * Path part for operation getMortgageBuildingById
   */
  static readonly GetMortgageBuildingByIdPath = '/api/v1/Buildings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildingById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildingById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetMortgageBuildingByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildingById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildingById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Building> {

    return this.getMortgageBuildingById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildingById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetMortgageBuildingByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildingById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Building> {

    return this.getMortgageBuildingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation patchMortgageBuilding
   */
  static readonly PatchMortgageBuildingPath = '/api/v1/Buildings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMortgageBuilding$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgageBuilding$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchMortgageBuildingPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMortgageBuilding$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgageBuilding$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Building> {

    return this.patchMortgageBuilding$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMortgageBuilding()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgageBuilding$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchMortgageBuildingPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMortgageBuilding$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgageBuilding(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Building> {

    return this.patchMortgageBuilding$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation getRentalContracts
   */
  static readonly GetRentalContractsPath = '/api/v1/Buildings/{id}/RentalContracts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContracts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContracts$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalContract>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetRentalContractsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalContract>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContracts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContracts$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<RentalContract>> {

    return this.getRentalContracts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalContract>>) => r.body as Array<RentalContract>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContracts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContracts$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalContract>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetRentalContractsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalContract>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContracts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContracts(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<RentalContract>> {

    return this.getRentalContracts$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalContract>>) => r.body as Array<RentalContract>)
    );
  }

  /**
   * Path part for operation getBuildingRooms
   */
  static readonly GetBuildingRoomsPath = '/api/v1/Buildings/{id}/Rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingRooms$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingRooms$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Room>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingRoomsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Room>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingRooms$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingRooms$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Room>> {

    return this.getBuildingRooms$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Room>>) => r.body as Array<Room>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingRooms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingRooms$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Room>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingRoomsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Room>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingRooms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingRooms(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Room>> {

    return this.getBuildingRooms$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Room>>) => r.body as Array<Room>)
    );
  }

  /**
   * Path part for operation getBuildingUnits
   */
  static readonly GetBuildingUnitsPath = '/api/v1/Buildings/{id}/Units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingUnits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingUnits$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingUnits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingUnits$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.getBuildingUnits$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingUnits$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingUnits(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.getBuildingUnits$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * Path part for operation addRenovationByBuildingId
   */
  static readonly AddRenovationByBuildingIdPath = '/api/v1/Buildings/{id}/Renovation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRenovationByBuildingId$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRenovationByBuildingId$Plain$Response(params: {
    id: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddRenovationByBuildingIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRenovationByBuildingId$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRenovationByBuildingId$Plain(params: {
    id: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.addRenovationByBuildingId$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRenovationByBuildingId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRenovationByBuildingId$Response(params: {
    id: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddRenovationByBuildingIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRenovationByBuildingId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRenovationByBuildingId(params: {
    id: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.addRenovationByBuildingId$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * Path part for operation patchRenovationByBuildingId
   */
  static readonly PatchRenovationByBuildingIdPath = '/api/v1/Buildings/{id}/Renovation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRenovationByBuildingId$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRenovationByBuildingId$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchRenovationByBuildingIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRenovationByBuildingId$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRenovationByBuildingId$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.patchRenovationByBuildingId$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRenovationByBuildingId()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRenovationByBuildingId$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchRenovationByBuildingIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRenovationByBuildingId$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRenovationByBuildingId(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.patchRenovationByBuildingId$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * Path part for operation addValuationByBuildingId
   */
  static readonly AddValuationByBuildingIdPath = '/api/v1/Buildings/{id}/Valuation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addValuationByBuildingId$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationByBuildingId$Plain$Response(params: {
    id: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddValuationByBuildingIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addValuationByBuildingId$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationByBuildingId$Plain(params: {
    id: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.addValuationByBuildingId$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addValuationByBuildingId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationByBuildingId$Response(params: {
    id: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddValuationByBuildingIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addValuationByBuildingId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationByBuildingId(params: {
    id: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.addValuationByBuildingId$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * Path part for operation patchValuationByBuildingId
   */
  static readonly PatchValuationByBuildingIdPath = '/api/v1/Buildings/{id}/Valuation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchValuationByBuildingId$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuationByBuildingId$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchValuationByBuildingIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchValuationByBuildingId$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuationByBuildingId$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.patchValuationByBuildingId$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchValuationByBuildingId()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuationByBuildingId$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchValuationByBuildingIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchValuationByBuildingId$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuationByBuildingId(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.patchValuationByBuildingId$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

}
