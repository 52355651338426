import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MarkdownModule } from 'ngx-markdown';
import { LoginModule } from './login/login.module';

// Localization
import { registerLocaleData } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import localeNl from '@angular/common/locales/nl';
import localeEN from '@angular/common/locales/en';
import { environment } from '@env/environment';
import { QuillConfig, QuillModule } from 'ngx-quill';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
registerLocaleData(localeNl, 'nl-NL');
registerLocaleData(localeEN, 'en-US');

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    SharedModule.forRoot(),
    MarkdownModule.forRoot(),
    ShellModule,
    QuillModule.forRoot(<QuillConfig>{
      format: 'html',
      placeholder: null,
      modules:  {
        toolbar: [
          ['bold', 'italic'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ]
      },
      theme: 'snow'
    }),
    LoginModule,
    PdfViewerModule,
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => translate.currentLang !== undefined ? translate.currentLang : environment.defaultLanguage,
      deps: [TranslateService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
