import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { I18nService } from '@app/core/i18n.service';
import { environment } from '@env/environment';

@Component({
  selector: 'vf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() sidenav!: MatSidenav;
  title = environment.app_name;

  actor: string;
  isLender: boolean;

  toolbarClass = '';

  constructor(
    private i18nService: I18nService,
    private authenticationService: AuthenticationService) { }


  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get surename(): string {
    const surname = this.authenticationService.user ? this.authenticationService.user.surname : '';
    return surname;
  }

  ngOnInit(): void {
    this.isLender = this.authenticationService.lender !== null;

    if (this.isLender) {
      this.actor = this.authenticationService.lender.name;
      this.toolbarClass = 'actor-lender';
    } else {
      this.actor = this.authenticationService.organization.name;
      this.toolbarClass = 'actor-organization';
    }
  }

  logout(): void {
    this.authenticationService.logout();
  }

  setLanguage(language: string): void {
    this.i18nService.language = language;
  }
}
