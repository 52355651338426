/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Customers } from '../models/customers';
import { JsonPatchDocument } from '../models/json-patch-document';
import { RentalContract } from '../models/rental-contract';

@Injectable({
  providedIn: 'root',
})
export class RentalContractsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRentalContractById
   */
  static readonly GetRentalContractByIdPath = '/api/v1/RentalContracts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContractById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RentalContract>> {

    const rb = new RequestBuilder(this.rootUrl, RentalContractsService.GetRentalContractByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalContract>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContractById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<RentalContract> {

    return this.getRentalContractById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<RentalContract>) => r.body as RentalContract)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContractById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RentalContract>> {

    const rb = new RequestBuilder(this.rootUrl, RentalContractsService.GetRentalContractByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalContract>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContractById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<RentalContract> {

    return this.getRentalContractById$Response(params,context).pipe(
      map((r: StrictHttpResponse<RentalContract>) => r.body as RentalContract)
    );
  }

  /**
   * Path part for operation patchRentalContract
   */
  static readonly PatchRentalContractPath = '/api/v1/RentalContracts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRentalContract$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRentalContract$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RentalContract>> {

    const rb = new RequestBuilder(this.rootUrl, RentalContractsService.PatchRentalContractPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalContract>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRentalContract$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRentalContract$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<RentalContract> {

    return this.patchRentalContract$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<RentalContract>) => r.body as RentalContract)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRentalContract()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRentalContract$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RentalContract>> {

    const rb = new RequestBuilder(this.rootUrl, RentalContractsService.PatchRentalContractPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalContract>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRentalContract$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRentalContract(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<RentalContract> {

    return this.patchRentalContract$Response(params,context).pipe(
      map((r: StrictHttpResponse<RentalContract>) => r.body as RentalContract)
    );
  }

  /**
   * Path part for operation getRentalContractLandlords
   */
  static readonly GetRentalContractLandlordsPath = '/api/v1/RentalContracts/{id}/Landlords';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContractLandlords$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractLandlords$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, RentalContractsService.GetRentalContractLandlordsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContractLandlords$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractLandlords$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getRentalContractLandlords$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContractLandlords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractLandlords$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, RentalContractsService.GetRentalContractLandlordsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContractLandlords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractLandlords(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getRentalContractLandlords$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * Path part for operation getRentalContractTenants
   */
  static readonly GetRentalContractTenantsPath = '/api/v1/RentalContracts/{id}/Tenants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContractTenants$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractTenants$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, RentalContractsService.GetRentalContractTenantsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContractTenants$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractTenants$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getRentalContractTenants$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContractTenants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractTenants$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, RentalContractsService.GetRentalContractTenantsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContractTenants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractTenants(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getRentalContractTenants$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

}
