/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Advice } from '../models/advice';
import { Application } from '../models/application';
import { ApplicationControl } from '../models/application-control';
import { ApplicationControlSourceDocument } from '../models/application-control-source-document';
import { ApplicationControlSourceDocuments } from '../models/application-control-source-documents';
import { ApplicationControlSourceResult } from '../models/application-control-source-result';
import { ApplicationControlSourceResults } from '../models/application-control-source-results';
import { ApplicationControlStatusHistory } from '../models/application-control-status-history';
import { ApplicationControls } from '../models/application-controls';
import { ApplicationRecord } from '../models/application-record';
import { ApplicationStatusHistory } from '../models/application-status-history';
import { ApplicationType } from '../models/application-type';
import { Applications } from '../models/applications';
import { Approval } from '../models/approval';
import { Approvals } from '../models/approvals';
import { BankStatementOwnResourcesData } from '../models/bank-statement-own-resources-data';
import { BankStatementRentalPaymentData } from '../models/bank-statement-rental-payment-data';
import { CalculationOptions } from '../models/calculation-options';
import { CollectionAccountData } from '../models/collection-account-data';
import { Customers } from '../models/customers';
import { DeliveryDeedData } from '../models/delivery-deed-data';
import { DeliveryDeedDraftData } from '../models/delivery-deed-draft-data';
import { DocumentContent } from '../models/document-content';
import { GroundLeaseDeedData } from '../models/ground-lease-deed-data';
import { IdentityDocumentData } from '../models/identity-document-data';
import { IntId } from '../models/int-id';
import { JsonPatchDocument } from '../models/json-patch-document';
import { MortgageDeedData } from '../models/mortgage-deed-data';
import { MortgageDocuments } from '../models/mortgage-documents';
import { NewRequiredDocument } from '../models/new-required-document';
import { Note } from '../models/note';
import { Notes } from '../models/notes';
import { ProformaRepaymentNoteData } from '../models/proforma-repayment-note-data';
import { Proposals } from '../models/proposals';
import { PublicAccess } from '../models/public-access';
import { PurchaseAgreementData } from '../models/purchase-agreement-data';
import { RentalAgreementData } from '../models/rental-agreement-data';
import { RequiredDocument } from '../models/required-document';
import { RequiredDocumentRecords } from '../models/required-document-records';
import { SubmitResult } from '../models/submit-result';
import { ValuationReportData } from '../models/valuation-report-data';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getApplications
   */
  static readonly GetApplicationsPath = '/api/v1/Applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplications$Response(params?: {
    filter?: 'Me' | 'Organization' | 'All';
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Applications>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationsPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Applications>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplications(params?: {
    filter?: 'Me' | 'Organization' | 'All';
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Applications> {

    return this.getApplications$Response(params,context).pipe(
      map((r: StrictHttpResponse<Applications>) => r.body as Applications)
    );
  }

  /**
   * Path part for operation getApplicationById
   */
  static readonly GetApplicationByIdPath = '/api/v1/Applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.getApplicationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation deleteApplicationById
   */
  static readonly DeleteApplicationByIdPath = '/api/v1/Applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApplicationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.DeleteApplicationByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApplicationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteApplicationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchApplication
   */
  static readonly PatchApplicationPath = '/api/v1/Applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchApplication()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchApplication$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.PatchApplicationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchApplication$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchApplication(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Application> {

    return this.patchApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation getApplicationRecordById
   */
  static readonly GetApplicationRecordByIdPath = '/api/v1/Applications/{id}/Record';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationRecordById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationRecordById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationRecord>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationRecordByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRecord>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationRecordById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationRecordById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<ApplicationRecord> {

    return this.getApplicationRecordById$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationRecord>) => r.body as ApplicationRecord)
    );
  }

  /**
   * Path part for operation getApplicationApplicantsById
   */
  static readonly GetApplicationApplicantsByIdPath = '/api/v1/Applications/{id}/Applicants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationApplicantsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApplicantsById$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationApplicantsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationApplicantsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApplicantsById(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getApplicationApplicantsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * Path part for operation getApplicationApprovals
   */
  static readonly GetApplicationApprovalsPath = '/api/v1/Applications/{id}/Approvals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationApprovals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApprovals$Response(params: {
    id: string;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Approved' | 'Open' | 'Rejected' | 'Pending'>;
    actors?: Array<'Lender' | 'Organization'>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Approvals>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationApprovalsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('phases', params.phases, {});
      rb.query('statuses', params.statuses, {});
      rb.query('actors', params.actors, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Approvals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationApprovals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApprovals(params: {
    id: string;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Approved' | 'Open' | 'Rejected' | 'Pending'>;
    actors?: Array<'Lender' | 'Organization'>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Approvals> {

    return this.getApplicationApprovals$Response(params,context).pipe(
      map((r: StrictHttpResponse<Approvals>) => r.body as Approvals)
    );
  }

  /**
   * Path part for operation getApplicationApproval
   */
  static readonly GetApplicationApprovalPath = '/api/v1/Applications/{id}/Approvals/{approvalId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationApproval()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApproval$Response(params: {
    id: string;
    approvalId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Approval>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationApprovalPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('approvalId', params.approvalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Approval>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationApproval$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApproval(params: {
    id: string;
    approvalId: string;
  },
  context?: HttpContext

): Observable<Approval> {

    return this.getApplicationApproval$Response(params,context).pipe(
      map((r: StrictHttpResponse<Approval>) => r.body as Approval)
    );
  }

  /**
   * Path part for operation getApplicationApprovalControls
   */
  static readonly GetApplicationApprovalControlsPath = '/api/v1/Applications/{id}/Approvals/{approvalId}/Controls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationApprovalControls()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApprovalControls$Response(params: {
    id: string;
    approvalId: string;
    statuses?: Array<'Open' | 'Approved' | 'Rejected' | 'Pending' | 'Error'>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControls>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationApprovalControlsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('approvalId', params.approvalId, {});
      rb.query('statuses', params.statuses, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControls>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationApprovalControls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApprovalControls(params: {
    id: string;
    approvalId: string;
    statuses?: Array<'Open' | 'Approved' | 'Rejected' | 'Pending' | 'Error'>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ApplicationControls> {

    return this.getApplicationApprovalControls$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControls>) => r.body as ApplicationControls)
    );
  }

  /**
   * Path part for operation getApplicationApprovalNotes
   */
  static readonly GetApplicationApprovalNotesPath = '/api/v1/Applications/{id}/Approvals/{approvalId}/Notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationApprovalNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApprovalNotes$Response(params: {
    id: string;
    approvalId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Notes>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationApprovalNotesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('approvalId', params.approvalId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationApprovalNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationApprovalNotes(params: {
    id: string;
    approvalId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Notes> {

    return this.getApplicationApprovalNotes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Notes>) => r.body as Notes)
    );
  }

  /**
   * Path part for operation addApplicationApprovalNote
   */
  static readonly AddApplicationApprovalNotePath = '/api/v1/Applications/{id}/Approvals/{approvalId}/Notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationApprovalNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  addApplicationApprovalNote$Response(params: {
    id: string;
    approvalId: string;
    note?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationApprovalNotePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('approvalId', params.approvalId, {});
      rb.query('note', params.note, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationApprovalNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addApplicationApprovalNote(params: {
    id: string;
    approvalId: string;
    note?: string;
  },
  context?: HttpContext

): Observable<Note> {

    return this.addApplicationApprovalNote$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * Path part for operation approveApplicationApproval
   */
  static readonly ApproveApplicationApprovalPath = '/api/v1/Applications/{id}/Approvals/{approvalId}/Approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveApplicationApproval()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  approveApplicationApproval$Response(params: {
    id: string;
    approvalId: string;
    body?: Advice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.ApproveApplicationApprovalPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('approvalId', params.approvalId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveApplicationApproval$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  approveApplicationApproval(params: {
    id: string;
    approvalId: string;
    body?: Advice
  },
  context?: HttpContext

): Observable<Application> {

    return this.approveApplicationApproval$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation rejectApplicationApproval
   */
  static readonly RejectApplicationApprovalPath = '/api/v1/Applications/{id}/Approvals/{approvalId}/Reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectApplicationApproval()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rejectApplicationApproval$Response(params: {
    id: string;
    approvalId: string;
    body?: Advice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RejectApplicationApprovalPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('approvalId', params.approvalId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectApplicationApproval$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rejectApplicationApproval(params: {
    id: string;
    approvalId: string;
    body?: Advice
  },
  context?: HttpContext

): Observable<Application> {

    return this.rejectApplicationApproval$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation getCalculationOptions
   */
  static readonly GetCalculationOptionsPath = '/api/v1/Applications/{id}/CalculationOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationOptions$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CalculationOptions>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetCalculationOptionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationOptions>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationOptions(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CalculationOptions> {

    return this.getCalculationOptions$Response(params,context).pipe(
      map((r: StrictHttpResponse<CalculationOptions>) => r.body as CalculationOptions)
    );
  }

  /**
   * Path part for operation cancelApplication
   */
  static readonly CancelApplicationPath = '/api/v1/Applications/{id}/Cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelApplication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancelApplication$Response(params: {
    id: string;
    body?: Advice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.CancelApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelApplication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancelApplication(params: {
    id: string;
    body?: Advice
  },
  context?: HttpContext

): Observable<Application> {

    return this.cancelApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation getApplicationCreditProposal
   */
  static readonly GetApplicationCreditProposalPath = '/api/v1/Applications/{id}/CreditProposal';

  /**
   * Returns a credit proposal in pdf format can be downloaded.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationCreditProposal()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationCreditProposal$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationCreditProposalPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Returns a credit proposal in pdf format can be downloaded.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationCreditProposal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationCreditProposal(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getApplicationCreditProposal$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getApplicationFinancingPlan
   */
  static readonly GetApplicationFinancingPlanPath = '/api/v1/Applications/{id}/FinancingPlan';

  /**
   * Returns a financing plan in pdf format can be downloaded.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationFinancingPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationFinancingPlan$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationFinancingPlanPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Returns a financing plan in pdf format can be downloaded.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationFinancingPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationFinancingPlan(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getApplicationFinancingPlan$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation lockApplication
   */
  static readonly LockApplicationPath = '/api/v1/Applications/{id}/Lock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockApplication$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.LockApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockApplication(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.lockApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation unlockApplication
   */
  static readonly UnlockApplicationPath = '/api/v1/Applications/{id}/Unlock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockApplication$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UnlockApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockApplication(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.unlockApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation getProposals
   */
  static readonly GetProposalsPath = '/api/v1/Applications/{id}/Proposals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProposals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposals$Response(params: {
    id: string;
    CalculationType?: 'All' | 'SingleLoan';
    'Filter.IncludeInvalid'?: boolean;
    'Filter.MaximumAdditionalIncomeRequired'?: number;
    'Filter.MaximumContributionOwnResources'?: number;
    'Filter.MaximumLoanToValue'?: number;
    'Filter.MaximumPeriod'?: number;
    'Filter.MaximumRepaymentFreePercentage'?: number;
    'Filter.MaximumRepaymentRate'?: number;
    'Filter.MinimumContributionOwnResources'?: number;
    'Filter.MinimumFreeCashFlow'?: number;
    'Filter.MinimumLoanToValue'?: number;
    'Filter.MinimumPeriod'?: number;
    'Filter.MinimumRepaymentFreePercentage'?: number;
    'Filter.MinimumRepaymentRate'?: number;
    'Filter.RepaymentType'?: 'Annuity' | 'Linear' | 'RepaymentFree';
    InterestDiscount?: number;
    Page?: number;
    PageCount?: number;
    PreferredLoan?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetProposalsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('CalculationType', params.CalculationType, {});
      rb.query('Filter.IncludeInvalid', params['Filter.IncludeInvalid'], {});
      rb.query('Filter.MaximumAdditionalIncomeRequired', params['Filter.MaximumAdditionalIncomeRequired'], {});
      rb.query('Filter.MaximumContributionOwnResources', params['Filter.MaximumContributionOwnResources'], {});
      rb.query('Filter.MaximumLoanToValue', params['Filter.MaximumLoanToValue'], {});
      rb.query('Filter.MaximumPeriod', params['Filter.MaximumPeriod'], {});
      rb.query('Filter.MaximumRepaymentFreePercentage', params['Filter.MaximumRepaymentFreePercentage'], {});
      rb.query('Filter.MaximumRepaymentRate', params['Filter.MaximumRepaymentRate'], {});
      rb.query('Filter.MinimumContributionOwnResources', params['Filter.MinimumContributionOwnResources'], {});
      rb.query('Filter.MinimumFreeCashFlow', params['Filter.MinimumFreeCashFlow'], {});
      rb.query('Filter.MinimumLoanToValue', params['Filter.MinimumLoanToValue'], {});
      rb.query('Filter.MinimumPeriod', params['Filter.MinimumPeriod'], {});
      rb.query('Filter.MinimumRepaymentFreePercentage', params['Filter.MinimumRepaymentFreePercentage'], {});
      rb.query('Filter.MinimumRepaymentRate', params['Filter.MinimumRepaymentRate'], {});
      rb.query('Filter.RepaymentType', params['Filter.RepaymentType'], {});
      rb.query('InterestDiscount', params.InterestDiscount, {});
      rb.query('Page', params.Page, {});
      rb.query('PageCount', params.PageCount, {});
      rb.query('PreferredLoan', params.PreferredLoan, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProposals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposals(params: {
    id: string;
    CalculationType?: 'All' | 'SingleLoan';
    'Filter.IncludeInvalid'?: boolean;
    'Filter.MaximumAdditionalIncomeRequired'?: number;
    'Filter.MaximumContributionOwnResources'?: number;
    'Filter.MaximumLoanToValue'?: number;
    'Filter.MaximumPeriod'?: number;
    'Filter.MaximumRepaymentFreePercentage'?: number;
    'Filter.MaximumRepaymentRate'?: number;
    'Filter.MinimumContributionOwnResources'?: number;
    'Filter.MinimumFreeCashFlow'?: number;
    'Filter.MinimumLoanToValue'?: number;
    'Filter.MinimumPeriod'?: number;
    'Filter.MinimumRepaymentFreePercentage'?: number;
    'Filter.MinimumRepaymentRate'?: number;
    'Filter.RepaymentType'?: 'Annuity' | 'Linear' | 'RepaymentFree';
    InterestDiscount?: number;
    Page?: number;
    PageCount?: number;
    PreferredLoan?: number;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.getProposals$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * Path part for operation requestAdditionalInformation
   */
  static readonly RequestAdditionalInformationPath = '/api/v1/Applications/{id}/RequestAdditionalInformation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestAdditionalInformation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestAdditionalInformation$Response(params: {
    id: string;
    body?: Advice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RequestAdditionalInformationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestAdditionalInformation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestAdditionalInformation(params: {
    id: string;
    body?: Advice
  },
  context?: HttpContext

): Observable<Application> {

    return this.requestAdditionalInformation$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation completePhaseApplication
   */
  static readonly CompletePhaseApplicationPath = '/api/v1/Applications/{id}/CompletePhase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completePhaseApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  completePhaseApplication$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.CompletePhaseApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completePhaseApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  completePhaseApplication(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.completePhaseApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation updateMortgage
   */
  static readonly UpdateMortgagePath = '/api/v1/Applications/{id}/Mortgage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMortgage()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMortgage$Response(params: {
    id: string;
    calculationId?: string;
    proposalId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UpdateMortgagePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('calculationId', params.calculationId, {});
      rb.query('proposalId', params.proposalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMortgage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMortgage(params: {
    id: string;
    calculationId?: string;
    proposalId?: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.updateMortgage$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation rejectApplication
   */
  static readonly RejectApplicationPath = '/api/v1/Applications/{id}/Reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectApplication$Response(params: {
    id: string;
    text?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RejectApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('text', params.text, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectApplication(params: {
    id: string;
    text?: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.rejectApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation submitApplication
   */
  static readonly SubmitApplicationPath = '/api/v1/Applications/{id}/Submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitApplication$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SubmitResult>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.SubmitApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubmitResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitApplication(params: {
    id: string;
  },
  context?: HttpContext

): Observable<SubmitResult> {

    return this.submitApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<SubmitResult>) => r.body as SubmitResult)
    );
  }

  /**
   * Path part for operation getApplicationCustomersById
   */
  static readonly GetApplicationCustomersByIdPath = '/api/v1/Applications/{id}/Customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationCustomersById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationCustomersById$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationCustomersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationCustomersById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationCustomersById(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getApplicationCustomersById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * Path part for operation getApplicationControls
   */
  static readonly GetApplicationControlsPath = '/api/v1/Applications/{id}/Controls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationControls()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControls$Response(params: {
    id: string;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Open' | 'Approved' | 'Rejected' | 'Pending' | 'Error'>;
    actors?: Array<'Lender' | 'Organization'>;
    controlTypes?: Array<'Application' | 'Building' | 'Customer' | 'RegisteredMortgage' | 'RentalContract' | 'Transaction' | 'CadastralDesignation'>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControls>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationControlsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('phases', params.phases, {});
      rb.query('statuses', params.statuses, {});
      rb.query('actors', params.actors, {});
      rb.query('controlTypes', params.controlTypes, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControls>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationControls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControls(params: {
    id: string;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Open' | 'Approved' | 'Rejected' | 'Pending' | 'Error'>;
    actors?: Array<'Lender' | 'Organization'>;
    controlTypes?: Array<'Application' | 'Building' | 'Customer' | 'RegisteredMortgage' | 'RentalContract' | 'Transaction' | 'CadastralDesignation'>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ApplicationControls> {

    return this.getApplicationControls$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControls>) => r.body as ApplicationControls)
    );
  }

  /**
   * Path part for operation manuallyAddControl
   */
  static readonly ManuallyAddControlPath = '/api/v1/Applications/{id}/Controls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manuallyAddControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  manuallyAddControl$Response(params: {
    id: string;
    number?: string;
    phase?: 'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing';
    buildingid?: string;
    customerId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.ManuallyAddControlPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('number', params.number, {});
      rb.query('phase', params.phase, {});
      rb.query('buildingid', params.buildingid, {});
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manuallyAddControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  manuallyAddControl(params: {
    id: string;
    number?: string;
    phase?: 'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing';
    buildingid?: string;
    customerId?: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.manuallyAddControl$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation getApplicationControl
   */
  static readonly GetApplicationControlPath = '/api/v1/Applications/{id}/Controls/{controlId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControl$Response(params: {
    id: string;
    controlId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControl>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationControlPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControl>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControl(params: {
    id: string;
    controlId: string;
  },
  context?: HttpContext

): Observable<ApplicationControl> {

    return this.getApplicationControl$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControl>) => r.body as ApplicationControl)
    );
  }

  /**
   * Path part for operation approveApplicationControl
   */
  static readonly ApproveApplicationControlPath = '/api/v1/Applications/{id}/Controls/{controlId}/Approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveApplicationControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveApplicationControl$Response(params: {
    id: string;
    controlId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControl>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.ApproveApplicationControlPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControl>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveApplicationControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveApplicationControl(params: {
    id: string;
    controlId: string;
  },
  context?: HttpContext

): Observable<ApplicationControl> {

    return this.approveApplicationControl$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControl>) => r.body as ApplicationControl)
    );
  }

  /**
   * Path part for operation rejectApplicationControl
   */
  static readonly RejectApplicationControlPath = '/api/v1/Applications/{id}/Controls/{controlId}/Reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectApplicationControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectApplicationControl$Response(params: {
    id: string;
    controlId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControl>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RejectApplicationControlPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControl>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectApplicationControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectApplicationControl(params: {
    id: string;
    controlId: string;
  },
  context?: HttpContext

): Observable<ApplicationControl> {

    return this.rejectApplicationControl$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControl>) => r.body as ApplicationControl)
    );
  }

  /**
   * Path part for operation reopenApplicationControl
   */
  static readonly ReopenApplicationControlPath = '/api/v1/Applications/{id}/Controls/{controlId}/Reopen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reopenApplicationControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  reopenApplicationControl$Response(params: {
    id: string;
    controlId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControl>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.ReopenApplicationControlPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControl>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reopenApplicationControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reopenApplicationControl(params: {
    id: string;
    controlId: string;
  },
  context?: HttpContext

): Observable<ApplicationControl> {

    return this.reopenApplicationControl$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControl>) => r.body as ApplicationControl)
    );
  }

  /**
   * Path part for operation getApplicationControlNotes
   */
  static readonly GetApplicationControlNotesPath = '/api/v1/Applications/{id}/Controls/{controlId}/Notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationControlNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControlNotes$Response(params: {
    id: string;
    controlId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Notes>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationControlNotesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationControlNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControlNotes(params: {
    id: string;
    controlId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Notes> {

    return this.getApplicationControlNotes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Notes>) => r.body as Notes)
    );
  }

  /**
   * Path part for operation addApplicationControlNote
   */
  static readonly AddApplicationControlNotePath = '/api/v1/Applications/{id}/Controls/{controlId}/Notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationControlNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  addApplicationControlNote$Response(params: {
    id: string;
    controlId: string;
    note?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationControlNotePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.query('note', params.note, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationControlNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addApplicationControlNote(params: {
    id: string;
    controlId: string;
    note?: string;
  },
  context?: HttpContext

): Observable<Note> {

    return this.addApplicationControlNote$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * Path part for operation getApplicationControlStatusHistory
   */
  static readonly GetApplicationControlStatusHistoryPath = '/api/v1/Applications/{id}/Controls/{controlId}/StatusHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationControlStatusHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControlStatusHistory$Response(params: {
    id: string;
    controlId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControlStatusHistory>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationControlStatusHistoryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControlStatusHistory>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationControlStatusHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControlStatusHistory(params: {
    id: string;
    controlId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ApplicationControlStatusHistory> {

    return this.getApplicationControlStatusHistory$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControlStatusHistory>) => r.body as ApplicationControlStatusHistory)
    );
  }

  /**
   * Path part for operation addControlSourceResultFromSource
   */
  static readonly AddControlSourceResultFromSourcePath = '/api/v1/Applications/{id}/Controls/{controlId}/Sources/{sourceId}/Fetch';

  /**
   * Fetches the result from the source api to attach to this control.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addControlSourceResultFromSource()` instead.
   *
   * This method doesn't expect any request body.
   */
  addControlSourceResultFromSource$Response(params: {
    id: string;
    controlId: string;
    sourceId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddControlSourceResultFromSourcePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.path('sourceId', params.sourceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Fetches the result from the source api to attach to this control.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addControlSourceResultFromSource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addControlSourceResultFromSource(params: {
    id: string;
    controlId: string;
    sourceId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.addControlSourceResultFromSource$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getApplicationControlSourceResults
   */
  static readonly GetApplicationControlSourceResultsPath = '/api/v1/Applications/{id}/Controls/{controlId}/SourceResults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationControlSourceResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControlSourceResults$Response(params: {
    id: string;
    controlId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControlSourceResults>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationControlSourceResultsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControlSourceResults>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationControlSourceResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControlSourceResults(params: {
    id: string;
    controlId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ApplicationControlSourceResults> {

    return this.getApplicationControlSourceResults$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControlSourceResults>) => r.body as ApplicationControlSourceResults)
    );
  }

  /**
   * Path part for operation addApplicationControlSourceResult
   */
  static readonly AddApplicationControlSourceResultPath = '/api/v1/Applications/{id}/Controls/{controlId}/SourceResults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationControlSourceResult()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationControlSourceResult$Response(params: {
    id: string;
    controlId: string;
    body?: ApplicationControlSourceResult
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControlSourceResult>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationControlSourceResultPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControlSourceResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationControlSourceResult$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationControlSourceResult(params: {
    id: string;
    controlId: string;
    body?: ApplicationControlSourceResult
  },
  context?: HttpContext

): Observable<ApplicationControlSourceResult> {

    return this.addApplicationControlSourceResult$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControlSourceResult>) => r.body as ApplicationControlSourceResult)
    );
  }

  /**
   * Path part for operation deleteApplicationControlSourceResult
   */
  static readonly DeleteApplicationControlSourceResultPath = '/api/v1/Applications/{id}/Controls/{controlId}/SourceResults/{sourceResultId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApplicationControlSourceResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationControlSourceResult$Response(params: {
    id: string;
    controlId: string;
    sourceResultId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.DeleteApplicationControlSourceResultPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.path('sourceResultId', params.sourceResultId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApplicationControlSourceResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationControlSourceResult(params: {
    id: string;
    controlId: string;
    sourceResultId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteApplicationControlSourceResult$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getApplicationControlSourceDocuments
   */
  static readonly GetApplicationControlSourceDocumentsPath = '/api/v1/Applications/{id}/Controls/{controlId}/SourceDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationControlSourceDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControlSourceDocuments$Response(params: {
    id: string;
    controlId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControlSourceDocuments>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationControlSourceDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControlSourceDocuments>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationControlSourceDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationControlSourceDocuments(params: {
    id: string;
    controlId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ApplicationControlSourceDocuments> {

    return this.getApplicationControlSourceDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControlSourceDocuments>) => r.body as ApplicationControlSourceDocuments)
    );
  }

  /**
   * Path part for operation registerApplicationControlSourceDocument
   */
  static readonly RegisterApplicationControlSourceDocumentPath = '/api/v1/Applications/{id}/Controls/{controlId}/SourceDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerApplicationControlSourceDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  registerApplicationControlSourceDocument$Response(params: {
    id: string;
    controlId: string;
    originalFileName?: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControlSourceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RegisterApplicationControlSourceDocumentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.query('originalFileName', params.originalFileName, {});
      rb.query('storageFileName', params.storageFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControlSourceDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerApplicationControlSourceDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registerApplicationControlSourceDocument(params: {
    id: string;
    controlId: string;
    originalFileName?: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<ApplicationControlSourceDocument> {

    return this.registerApplicationControlSourceDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControlSourceDocument>) => r.body as ApplicationControlSourceDocument)
    );
  }

  /**
   * Path part for operation deleteApplicationControlSourceDocument
   */
  static readonly DeleteApplicationControlSourceDocumentPath = '/api/v1/Applications/{id}/Controls/{controlId}/SourceDocuments/{sourceDocumentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApplicationControlSourceDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationControlSourceDocument$Response(params: {
    id: string;
    controlId: string;
    sourceDocumentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.DeleteApplicationControlSourceDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('controlId', params.controlId, {});
      rb.path('sourceDocumentId', params.sourceDocumentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApplicationControlSourceDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationControlSourceDocument(params: {
    id: string;
    controlId: string;
    sourceDocumentId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteApplicationControlSourceDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getApplicationDocumentsById
   */
  static readonly GetApplicationDocumentsByIdPath = '/api/v1/Applications/{id}/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationDocumentsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationDocumentsById$Response(params: {
    id: string;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Pending' | 'Received' | 'Accepted' | 'Rejected' | 'Open'>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocumentRecords>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationDocumentsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('phases', params.phases, {});
      rb.query('statuses', params.statuses, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocumentRecords>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationDocumentsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationDocumentsById(params: {
    id: string;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Pending' | 'Received' | 'Accepted' | 'Rejected' | 'Open'>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<RequiredDocumentRecords> {

    return this.getApplicationDocumentsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocumentRecords>) => r.body as RequiredDocumentRecords)
    );
  }

  /**
   * Path part for operation addDocument
   */
  static readonly AddDocumentPath = '/api/v1/Applications/{id}/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDocument()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addDocument$Response(params: {
    id: string;
    body?: NewRequiredDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddDocumentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDocument$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addDocument(params: {
    id: string;
    body?: NewRequiredDocument
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.addDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation getDocumentContent
   */
  static readonly GetDocumentContentPath = '/api/v1/Applications/{id}/Documents/{documentId}/Content';

  /**
   * Get the document contents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentContent$Response(params: {

    /**
     * Id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentContent>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetDocumentContentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentContent>;
      })
    );
  }

  /**
   * Get the document contents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentContent(params: {

    /**
     * Id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;
  },
  context?: HttpContext

): Observable<DocumentContent> {

    return this.getDocumentContent$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentContent>) => r.body as DocumentContent)
    );
  }

  /**
   * Path part for operation getControlsByDocumentId
   */
  static readonly GetControlsByDocumentIdPath = '/api/v1/Applications/{id}/Documents/{documentId}/Controls';

  /**
   * Get the application controls associated with the document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getControlsByDocumentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlsByDocumentId$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationControls>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetControlsByDocumentIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationControls>;
      })
    );
  }

  /**
   * Get the application controls associated with the document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getControlsByDocumentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlsByDocumentId(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<ApplicationControls> {

    return this.getControlsByDocumentId$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationControls>) => r.body as ApplicationControls)
    );
  }

  /**
   * Path part for operation getDocumentData
   */
  static readonly GetDocumentDataPath = '/api/v1/Applications/{id}/Documents/{documentId}/Data';

  /**
   * Get the document data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentData$Response(params: {

    /**
     * Id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<(BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetDocumentDataPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)>;
      })
    );
  }

  /**
   * Get the document data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentData(params: {

    /**
     * Id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;
  },
  context?: HttpContext

): Observable<(BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)> {

    return this.getDocumentData$Response(params,context).pipe(
      map((r: StrictHttpResponse<(BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)>) => r.body as (BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData))
    );
  }

  /**
   * Path part for operation updateDocumentData
   */
  static readonly UpdateDocumentDataPath = '/api/v1/Applications/{id}/Documents/{documentId}/Data';

  /**
   * Updates the document data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDocumentData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateDocumentData$Response(params: {
    id: string;
    documentId: string;
    body?: (BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UpdateDocumentDataPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Updates the document data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDocumentData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateDocumentData(params: {
    id: string;
    documentId: string;
    body?: (BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.updateDocumentData$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation loadDocumentData
   */
  static readonly LoadDocumentDataPath = '/api/v1/Applications/{id}/Documents/{documentId}/LoadData';

  /**
   * Load the document data from a file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadDocumentData()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadDocumentData$Response(params: {
    id: string;
    documentId: string;
    originalFileName?: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<(BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.LoadDocumentDataPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
      rb.query('originalFileName', params.originalFileName, {});
      rb.query('storageFileName', params.storageFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)>;
      })
    );
  }

  /**
   * Load the document data from a file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadDocumentData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadDocumentData(params: {
    id: string;
    documentId: string;
    originalFileName?: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<(BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)> {

    return this.loadDocumentData$Response(params,context).pipe(
      map((r: StrictHttpResponse<(BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData)>) => r.body as (BankStatementOwnResourcesData | BankStatementRentalPaymentData | CollectionAccountData | DeliveryDeedData | DeliveryDeedDraftData | GroundLeaseDeedData | IdentityDocumentData | MortgageDeedData | ProformaRepaymentNoteData | PurchaseAgreementData | RentalAgreementData | ValuationReportData))
    );
  }

  /**
   * Path part for operation zipDocuments
   */
  static readonly ZipDocumentsPath = '/api/v1/Applications/{id}/Documents/Zip';

  /**
   * Zips the uploaded required documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zipDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  zipDocuments$Response(params: {
    id: string;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Pending' | 'Received' | 'Accepted' | 'Rejected' | 'Open'>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.ZipDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('phases', params.phases, {});
      rb.query('statuses', params.statuses, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Zips the uploaded required documents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zipDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  zipDocuments(params: {
    id: string;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Pending' | 'Received' | 'Accepted' | 'Rejected' | 'Open'>;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.zipDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation deleteDocument
   */
  static readonly DeleteDocumentPath = '/api/v1/Applications/{id}/Documents/{documentId}';

  /**
   * Deletes a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocument$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.DeleteDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocument(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchDocument
   */
  static readonly PatchDocumentPath = '/api/v1/Applications/{id}/Documents/{documentId}';

  /**
   * Patch a document to change properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDocument()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchDocument$Response(params: {

    /**
     * Id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.PatchDocumentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Patch a document to change properties.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDocument$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchDocument(params: {

    /**
     * Id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.patchDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation acceptDocument
   */
  static readonly AcceptDocumentPath = '/api/v1/Applications/{id}/Documents/{documentId}/Accept';

  /**
   * Accepts the content of the document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptDocument$Response(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AcceptDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Accepts the content of the document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptDocument(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.acceptDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation rejectDocument
   */
  static readonly RejectDocumentPath = '/api/v1/Applications/{id}/Documents/{documentId}/Reject';

  /**
   * Rejects the content of the document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectDocument$Response(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;

    /**
     * The reason for rejection
     */
    reason?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RejectDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
      rb.query('reason', params.reason, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Rejects the content of the document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectDocument(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    documentId: string;

    /**
     * The reason for rejection
     */
    reason?: string;
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.rejectDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation attachDocumentFile
   */
  static readonly AttachDocumentFilePath = '/api/v1/Applications/{id}/Documents/{documentId}/Attach';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachDocumentFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  attachDocumentFile$Response(params: {
    id: string;
    documentId: string;
    originalFileName?: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AttachDocumentFilePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
      rb.query('originalFileName', params.originalFileName, {});
      rb.query('storageFileName', params.storageFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachDocumentFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attachDocumentFile(params: {
    id: string;
    documentId: string;
    originalFileName?: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.attachDocumentFile$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation getApplicationNotes
   */
  static readonly GetApplicationNotesPath = '/api/v1/Applications/{id}/Notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationNotes$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Notes>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationNotesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationNotes(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Notes> {

    return this.getApplicationNotes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Notes>) => r.body as Notes)
    );
  }

  /**
   * Path part for operation addApplicationNote
   */
  static readonly AddApplicationNotePath = '/api/v1/Applications/{id}/Notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  addApplicationNote$Response(params: {
    id: string;
    note?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationNotePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('note', params.note, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addApplicationNote(params: {
    id: string;
    note?: string;
  },
  context?: HttpContext

): Observable<Note> {

    return this.addApplicationNote$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * Path part for operation getApplicationPhaseDocuments
   */
  static readonly GetApplicationPhaseDocumentsPath = '/api/v1/Applications/{id}/Phase/Documents';

  /**
   * Returns a list of required documents for completing the current phase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationPhaseDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationPhaseDocuments$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageDocuments>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationPhaseDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageDocuments>;
      })
    );
  }

  /**
   * Returns a list of required documents for completing the current phase.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationPhaseDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationPhaseDocuments(params: {
    id: string;
  },
  context?: HttpContext

): Observable<MortgageDocuments> {

    return this.getApplicationPhaseDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageDocuments>) => r.body as MortgageDocuments)
    );
  }

  /**
   * Path part for operation getApplicationStatusHistory
   */
  static readonly GetApplicationStatusHistoryPath = '/api/v1/Applications/{id}/StatusHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationStatusHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStatusHistory$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationStatusHistory>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationStatusHistoryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationStatusHistory>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationStatusHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStatusHistory(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ApplicationStatusHistory> {

    return this.getApplicationStatusHistory$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationStatusHistory>) => r.body as ApplicationStatusHistory)
    );
  }

  /**
   * Path part for operation getApplicationTypes
   */
  static readonly GetApplicationTypesPath = '/api/v1/ApplicationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ApplicationType>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ApplicationType>> {

    return this.getApplicationTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationType>>) => r.body as Array<ApplicationType>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ApplicationType>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes(params?: {
  },
  context?: HttpContext

): Observable<Array<ApplicationType>> {

    return this.getApplicationTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationType>>) => r.body as Array<ApplicationType>)
    );
  }

  /**
   * Path part for operation addApplicationType
   */
  static readonly AddApplicationTypePath = '/api/v1/ApplicationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationType$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationType$Plain$Response(params?: {
    body?: ApplicationType
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IntId>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationType$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationType$Plain(params?: {
    body?: ApplicationType
  },
  context?: HttpContext

): Observable<IntId> {

    return this.addApplicationType$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<IntId>) => r.body as IntId)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationType$Response(params?: {
    body?: ApplicationType
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IntId>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationType(params?: {
    body?: ApplicationType
  },
  context?: HttpContext

): Observable<IntId> {

    return this.addApplicationType$Response(params,context).pipe(
      map((r: StrictHttpResponse<IntId>) => r.body as IntId)
    );
  }

}
