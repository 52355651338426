import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Event, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'vf-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
  loading = false;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    // Don't reuse routes in order to reload page when activating the same route.
    // E.g. when clicking on applications when use is on the applications page the page will now refresh
    this.router.routeReuseStrategy.shouldReuseRoute = (): boolean => false;

    // Automatically close side menu on screens > sm breakpoint
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Medium])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.sidenav.close();
        }
      });

    this.router.events.pipe(untilDestroyed(this)).subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.loading = false;
    }
  }
}
