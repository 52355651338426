/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IncomeTest } from '../models/income-test';
import { InvestmentProfile } from '../models/investment-profile';
import { JsonPatchDocument } from '../models/json-patch-document';
import { Person } from '../models/person';

@Injectable({
  providedIn: 'root',
})
export class PersonsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPersonById
   */
  static readonly GetPersonByIdPath = '/api/v1/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersonById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Person> {

    return this.getPersonById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersonById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Person> {

    return this.getPersonById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * Path part for operation patchPersonById
   */
  static readonly PatchPersonByIdPath = '/api/v1/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPersonById$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPersonById$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.PatchPersonByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPersonById$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPersonById$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Person> {

    return this.patchPersonById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPersonById()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPersonById$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.PatchPersonByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPersonById$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPersonById(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Person> {

    return this.patchPersonById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * Path part for operation updateIncomeTest
   */
  static readonly UpdateIncomeTestPath = '/api/v1/Persons/{id}/IncomeTest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIncomeTest$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIncomeTest$Plain$Response(params: {
    id: string;
    body?: IncomeTest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IncomeTest>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateIncomeTestPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IncomeTest>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIncomeTest$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIncomeTest$Plain(params: {
    id: string;
    body?: IncomeTest
  },
  context?: HttpContext

): Observable<IncomeTest> {

    return this.updateIncomeTest$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<IncomeTest>) => r.body as IncomeTest)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIncomeTest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIncomeTest$Response(params: {
    id: string;
    body?: IncomeTest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IncomeTest>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateIncomeTestPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IncomeTest>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIncomeTest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIncomeTest(params: {
    id: string;
    body?: IncomeTest
  },
  context?: HttpContext

): Observable<IncomeTest> {

    return this.updateIncomeTest$Response(params,context).pipe(
      map((r: StrictHttpResponse<IncomeTest>) => r.body as IncomeTest)
    );
  }

  /**
   * Path part for operation updateInvestmentProfile
   */
  static readonly UpdateInvestmentProfilePath = '/api/v1/Persons/{id}/InvestmentProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInvestmentProfile$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvestmentProfile$Plain$Response(params: {
    id: string;
    body?: InvestmentProfile
  },
  context?: HttpContext

): Observable<StrictHttpResponse<InvestmentProfile>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateInvestmentProfilePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvestmentProfile>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInvestmentProfile$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvestmentProfile$Plain(params: {
    id: string;
    body?: InvestmentProfile
  },
  context?: HttpContext

): Observable<InvestmentProfile> {

    return this.updateInvestmentProfile$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<InvestmentProfile>) => r.body as InvestmentProfile)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInvestmentProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvestmentProfile$Response(params: {
    id: string;
    body?: InvestmentProfile
  },
  context?: HttpContext

): Observable<StrictHttpResponse<InvestmentProfile>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateInvestmentProfilePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvestmentProfile>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInvestmentProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvestmentProfile(params: {
    id: string;
    body?: InvestmentProfile
  },
  context?: HttpContext

): Observable<InvestmentProfile> {

    return this.updateInvestmentProfile$Response(params,context).pipe(
      map((r: StrictHttpResponse<InvestmentProfile>) => r.body as InvestmentProfile)
    );
  }

  /**
   * Path part for operation updateTaxPartner
   */
  static readonly UpdateTaxPartnerPath = '/api/v1/Persons/{id}/TaxPartner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTaxPartner$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTaxPartner$Plain$Response(params: {
    id: string;
    body?: Person
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateTaxPartnerPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTaxPartner$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTaxPartner$Plain(params: {
    id: string;
    body?: Person
  },
  context?: HttpContext

): Observable<Person> {

    return this.updateTaxPartner$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTaxPartner()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTaxPartner$Response(params: {
    id: string;
    body?: Person
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateTaxPartnerPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTaxPartner$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTaxPartner(params: {
    id: string;
    body?: Person
  },
  context?: HttpContext

): Observable<Person> {

    return this.updateTaxPartner$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

}
