import { Injectable } from '@angular/core';

import { User } from '@app/core/api/models';
import { OrganizationsService } from '@app/core/api/services';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class UserResolve  {

  constructor(
    private authenticationService: AuthenticationService,
    private organizationsService: OrganizationsService
  ) {

  }

  resolve(): Observable<User> {
    return this.authenticationService.getUser$().pipe(switchMap(() => this.organizationsService.getUserById({
      id: this.authenticationService.organizationId,
      userId: this.authenticationService.userId
    }).pipe(
      tap((user: User) => {
        if (user === null) {
          alert('We could not find the user (' + this.authenticationService.userId + '), please contact the IT-department.');
          throw new Error('No valid user');
        } else {
          this.authenticationService.user = user;
        }
      }),
      catchError(() => {
        this.authenticationService.logout();
        return EMPTY;
      })
    ))
    );
  }
}
