import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

export interface Toast {
  type: ToastType;
  message: string;
}

export enum ToastType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

@Injectable()
export class ToasterService {

  private subject = new Subject<Toast>();

  constructor(private readonly translate: TranslateService) { }

  getToast(): Observable<Toast> {
    return this.subject.asObservable();
  }

  created(message: string): void {
    this.toast(message + ' ' + this.translate.instant('is created!'), ToastType.success);
  }

  updated(message: string): void {
    this.toast(message + ' ' + this.translate.instant('is updated!'), ToastType.success);
  }

  deleted(message: string): void {
    this.toast(message + ' ' + this.translate.instant('is deleted!'), ToastType.success);
  }

  info(message: string): void {
    this.toast(message, ToastType.info);
  }

  success(message: string): void {
    this.toast(message, ToastType.success);
  }

  warning(message: string): void {
    this.toast(message, ToastType.warning);
  }

  error(message: string): void {
    this.toast(message, ToastType.error);
  }

  toast(message: string, type: ToastType = ToastType.info): void {
    this.subject.next(<Toast>{ message: message, type: type });
  }

  /**
   * Clear toasts
   */
  clear(): void {
    this.subject.next(null);
  }
}
