import { Injectable } from '@angular/core';

import { Lender } from '@app/core/api/models';
import { LendersService } from '@app/core/api/services';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';

@Injectable()
export class LenderResolve  {

  constructor(
    private authenticationService: AuthenticationService,
    private lendersService: LendersService
  ) {
  }

  resolve(): Observable<Lender> {
    return this.authenticationService.getUser$().pipe(switchMap(() => {
      if (this.authenticationService.lenderId === null) {
        return of(null);
      } else {
        return this.lendersService.getLenderById({ id: this.authenticationService.lenderId }).pipe(
          tap((lender: Lender) => {
            if (lender === null) {
              alert('We could not find the lender (' + this.authenticationService.lenderId + '), please contact the IT-department.');
            }
            this.authenticationService.lender = lender;
          }),
          catchError(() => {
            alert('We could not retrieve the lender (server error), please contact the IT-department');
            return EMPTY;
          })
        );
      }
    })
    );
  }
}
