import { Injectable } from '@angular/core';
import { PublicAccessService } from '@app/core/api/services';
import { AnonymousCredential, ContainerClient, newPipeline } from '@azure/storage-blob';
import { Guid } from 'guid-typescript';

type FileUploadProgressCallback = (percentageComplete: number) => void;
type FileUploadCompletedCallback = (uploadComplete: FileUploadComplete) => void;
type FileUploadErrorCallback = () => void;

@Injectable({
  providedIn: 'root'
})

/**
 * Provides a generic service to upload document files to
 * the azure blob storage and register them into the system
 */
export class FileUploadService {

  /**
   * An array with all the uploads currently being tracked
   */
  public uploads: FilesUploadProgress[] = [];

  constructor(private publicAccessService: PublicAccessService) { }

  /**
   * Registers the file and uploads the file into the storage
   * with an obtained public shared access signature
   *
   * @param file the dropped file into the box
   */
  public uploadFile(file: File, progressCallback?: FileUploadProgressCallback, completedCallback?: FileUploadCompletedCallback, errorCallback?: FileUploadErrorCallback): void {

    // Register the file
    const fileUpload = <FilesUploadProgress>{

      file: file,
      originalFileName: file.name,
      storageFileName: null,
      percentageComplete: 0,
      isComplete: false,
      progressCallback: progressCallback,
      completedCallback: completedCallback
    };

    // Obtain the public access key and start uploading it
    this.publicAccessService.getPublicAccessForDocumentUpload().subscribe(access => {

      const credentials = new AnonymousCredential();
      const pipeline = newPipeline(credentials);
      const containerClient = new ContainerClient(access.publicUri, pipeline);
      const documentId = Guid.create().toString();

      // Create a blob, we use the generated document id as the file name but preserve the extension
      // so the storage can accurately determine the correct mimetype. This limits warning
      // when we later download the file and the storage facility serves the file with application/octet-stream mimetype
      const fileExtension = this.getFileExtension(fileUpload.originalFileName);
      fileUpload.storageFileName = documentId + fileExtension;

      const blockBlobClient = containerClient.getBlockBlobClient(fileUpload.storageFileName);
      const uploadBlobResponse = blockBlobClient.upload(file, file.size, {
        onProgress(progress) {
          fileUpload.percentageComplete = progress.loadedBytes / file.size * 100;

          if (fileUpload.progressCallback) {
            fileUpload.progressCallback(fileUpload.percentageComplete);
          }
        },
      });

      uploadBlobResponse.then(t => {
        fileUpload.percentageComplete = 100;

        if (fileUpload.progressCallback) {
          fileUpload.progressCallback(100);
        }

        // Invoke the complete callback
        fileUpload.completedCallback({
          originalFileName: fileUpload.originalFileName,
          storageFileName: fileUpload.storageFileName
        });
      });

      this.uploads.push(fileUpload);

    }, () => {
      errorCallback();
    });
  }

  /**
   * Extracts the extension from the file name
   *
   * @param fileName The file name to extract the extension from
   */

  private getFileExtension(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.'), fileName.length - fileName.lastIndexOf('.')).toLowerCase();
  }
}

/**
 * Holding the file to be uploaded and details on the progress of the upload
 */
export interface FilesUploadProgress {
  file: File;
  originalFileName: string;
  storageFileName: string;
  percentageComplete: number;
  isComplete: boolean;
  progressCallback: FileUploadProgressCallback;
  completedCallback: FileUploadCompletedCallback;
}

export interface FileUploadComplete {
  originalFileName: string;
  storageFileName: string;
}
