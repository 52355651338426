<div class="mat-typography flex flex-row justify-start items-center content-center basis-full">
  <section class="login-section flex justify-center items-center content-center lg:basis-1/3 basis-full">
    <div class="flex flex-col justify-center items-stretch content-center">
      <img src="assets/unite-logo.svg" alt="Unite" class="logo"/>
      <button mat-raised-button color="primary" (click)="login()" [disabled]="isLoading">
        <span translate>Login</span>
      </button>
    </div>

    <div class="float-button">
      <button mat-button [matMenuTriggerFor]="languageMenu">
        <span *ngIf="currentLanguage == 'en-US'" translate>English</span>
        <span *ngIf="currentLanguage == 'nl-NL'" translate>Dutch</span>
      </button>
      <mat-menu #languageMenu="matMenu">
        <button [disabled]="currentLanguage == 'nl-NL'" mat-menu-item (click)="setLanguage('nl-NL')">
          <mat-icon>language</mat-icon>
          <span translate>Set to Dutch</span>
        </button>
        <button [disabled]="currentLanguage == 'en-US'" mat-menu-item (click)="setLanguage('en-US')">
          <mat-icon>language</mat-icon>
          <span translate>Set to English</span>
        </button>
      </mat-menu>
    </div>
  </section>
  <section class="right-banner hidden lg:basis-2/3 lg:block">
  </section>
</div>