import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { I18nService } from '@app/core/i18n.service';

@Component({
  selector: 'vf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  error: string | undefined;
  loginForm!: UntypedFormGroup;
  isLoading = false;

  constructor(
    private i18nService: I18nService,
    private authenticationService: AuthenticationService
  ) {
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  login(): void {
    this.isLoading = true;
    this.authenticationService.login();
  }

  setLanguage(language: string): void {
    this.i18nService.language = language;
  }
}
