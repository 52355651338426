/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Application } from '../models/application';
import { GuidId } from '../models/guid-id';
import { IntId } from '../models/int-id';
import { Lender } from '../models/lender';
import { Mortgage } from '../models/mortgage';
import { Rate } from '../models/rate';

@Injectable({
  providedIn: 'root',
})
export class LendersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLenders
   */
  static readonly GetLendersPath = '/api/v1/Lenders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenders$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Lender>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLendersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lender>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenders$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<Lender>> {

    return this.getLenders$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Lender>>) => r.body as Array<Lender>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenders$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Lender>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLendersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lender>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenders(params?: {
  },
  context?: HttpContext

): Observable<Array<Lender>> {

    return this.getLenders$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Lender>>) => r.body as Array<Lender>)
    );
  }

  /**
   * Path part for operation addLender
   */
  static readonly AddLenderPath = '/api/v1/Lenders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLender$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addLender$Plain$Response(params?: {
    body?: Lender
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IntId>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.AddLenderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addLender$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addLender$Plain(params?: {
    body?: Lender
  },
  context?: HttpContext

): Observable<IntId> {

    return this.addLender$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<IntId>) => r.body as IntId)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLender()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addLender$Response(params?: {
    body?: Lender
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IntId>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.AddLenderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addLender$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addLender(params?: {
    body?: Lender
  },
  context?: HttpContext

): Observable<IntId> {

    return this.addLender$Response(params,context).pipe(
      map((r: StrictHttpResponse<IntId>) => r.body as IntId)
    );
  }

  /**
   * Path part for operation getLenderById
   */
  static readonly GetLenderByIdPath = '/api/v1/Lenders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Lender>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Lender> {

    return this.getLenderById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Lender>) => r.body as Lender)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Lender>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Lender> {

    return this.getLenderById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Lender>) => r.body as Lender)
    );
  }

  /**
   * Path part for operation updateLender
   */
  static readonly UpdateLenderPath = '/api/v1/Lenders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLender$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLender$Plain$Response(params: {
    id: string;
    body?: Lender
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Lender>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.UpdateLenderPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLender$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLender$Plain(params: {
    id: string;
    body?: Lender
  },
  context?: HttpContext

): Observable<Lender> {

    return this.updateLender$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Lender>) => r.body as Lender)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLender()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLender$Response(params: {
    id: string;
    body?: Lender
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Lender>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.UpdateLenderPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLender$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLender(params: {
    id: string;
    body?: Lender
  },
  context?: HttpContext

): Observable<Lender> {

    return this.updateLender$Response(params,context).pipe(
      map((r: StrictHttpResponse<Lender>) => r.body as Lender)
    );
  }

  /**
   * Path part for operation deleteLenderById
   */
  static readonly DeleteLenderByIdPath = '/api/v1/Lenders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLenderById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLenderById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.DeleteLenderByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteLenderById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLenderById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteLenderById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLenderRatesById
   */
  static readonly GetLenderRatesByIdPath = '/api/v1/Lenders/{id}/Rates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderRatesById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderRatesById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Rate>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderRatesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rate>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderRatesById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderRatesById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Rate>> {

    return this.getLenderRatesById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Rate>>) => r.body as Array<Rate>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderRatesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderRatesById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Rate>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderRatesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rate>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderRatesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderRatesById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Rate>> {

    return this.getLenderRatesById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Rate>>) => r.body as Array<Rate>)
    );
  }

  /**
   * Path part for operation getLenderApplicationsById
   */
  static readonly GetLenderApplicationsByIdPath = '/api/v1/Lenders/{id}/Applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderApplicationsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderApplicationsById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Application>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderApplicationsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Application>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderApplicationsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderApplicationsById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Application>> {

    return this.getLenderApplicationsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Application>>) => r.body as Array<Application>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderApplicationsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderApplicationsById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Application>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderApplicationsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Application>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderApplicationsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderApplicationsById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Application>> {

    return this.getLenderApplicationsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Application>>) => r.body as Array<Application>)
    );
  }

  /**
   * Path part for operation getLenderMortgagesById
   */
  static readonly GetLenderMortgagesByIdPath = '/api/v1/Lenders/{id}/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderMortgagesById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderMortgagesById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Mortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderMortgagesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Mortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderMortgagesById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderMortgagesById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Mortgage>> {

    return this.getLenderMortgagesById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Mortgage>>) => r.body as Array<Mortgage>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderMortgagesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderMortgagesById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Mortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderMortgagesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Mortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderMortgagesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderMortgagesById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Mortgage>> {

    return this.getLenderMortgagesById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Mortgage>>) => r.body as Array<Mortgage>)
    );
  }

  /**
   * Path part for operation getRateById
   */
  static readonly GetRateByIdPath = '/api/v1/Rates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRateById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRateById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Rate>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetRateByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Rate>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRateById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRateById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Rate> {

    return this.getRateById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Rate>) => r.body as Rate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRateById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Rate>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetRateByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Rate>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRateById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Rate> {

    return this.getRateById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Rate>) => r.body as Rate)
    );
  }

  /**
   * Path part for operation updateRate
   */
  static readonly UpdateRatePath = '/api/v1/Rates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateRate$Plain$Response(params: {
    id: string;
    body?: Rate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Rate>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.UpdateRatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Rate>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateRate$Plain(params: {
    id: string;
    body?: Rate
  },
  context?: HttpContext

): Observable<Rate> {

    return this.updateRate$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Rate>) => r.body as Rate)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateRate$Response(params: {
    id: string;
    body?: Rate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Rate>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.UpdateRatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Rate>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateRate(params: {
    id: string;
    body?: Rate
  },
  context?: HttpContext

): Observable<Rate> {

    return this.updateRate$Response(params,context).pipe(
      map((r: StrictHttpResponse<Rate>) => r.body as Rate)
    );
  }

  /**
   * Path part for operation deleteRateById
   */
  static readonly DeleteRateByIdPath = '/api/v1/Rates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRateById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.DeleteRateByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRateById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteRateById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addRate
   */
  static readonly AddRatePath = '/api/v1/Rates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRate$Plain$Response(params?: {
    body?: Rate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GuidId>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.AddRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRate$Plain(params?: {
    body?: Rate
  },
  context?: HttpContext

): Observable<GuidId> {

    return this.addRate$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<GuidId>) => r.body as GuidId)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRate$Response(params?: {
    body?: Rate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GuidId>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.AddRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRate(params?: {
    body?: Rate
  },
  context?: HttpContext

): Observable<GuidId> {

    return this.addRate$Response(params,context).pipe(
      map((r: StrictHttpResponse<GuidId>) => r.body as GuidId)
    );
  }

}
