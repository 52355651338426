import { Injectable } from '@angular/core';

import { Organization } from '@app/core/api/models';
import { OrganizationsService } from '@app/core/api/services';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class OrganizationResolve  {

  constructor(
    private authenticationService: AuthenticationService,
    private organizationsService: OrganizationsService
  ) {
  }

  resolve(): Observable<Organization> {
    return this.authenticationService.getUser$().pipe(switchMap(() => this.organizationsService.getOrganizationById({ id: this.authenticationService.organizationId }).pipe(
      tap((organization: Organization) => {
        if (organization === null) {
          alert('We could not find the organization (' + this.authenticationService.organizationId + '), please contact the IT-department.');
          throw new Error('No valid organization');
        } else {
          this.authenticationService.organization = organization;
        }
      }),
      catchError(() => {
        alert('We could not find the organization (' + this.authenticationService.organizationId + ') because of a technical error, please contact the IT-department.');
        this.authenticationService.logout();
        return EMPTY;
      })
    ))
    );
  }
}
