import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { ActorFilterComponent } from './actor-filter/actor-filter.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { ApplicationPhaseFilterComponent } from './application-phase-filter/application-phase-filter.component';
import { ApplicationPhaseSelectComponent } from './application-phase-select/application-phase-select.component';
import { ApprovalStatusFilterComponent } from './approval-status-filter/approval-status-filter.component';
import { AuditListItemComponent } from './audit-list-item/audit-list-item.component';
import { BorrowerAutocompleteComponent } from './borrower-autocomplete/borrower-autocomplete.component';
import { BuildingAutocompleteComponent } from './building-autocomplete/building-autocomplete.component';
import { CardActions, CardComponent, CardContent, CardFooter, CardHeaderComponent, CardTitle } from './card/card.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { ChildDetailHeaderComponent } from './child-detail-header/child-detail-header.component';
import { ControlAutocompleteComponent } from './control-autocomplete/control-autocomplete.component';
import { ControlStatusFilterComponent } from './control-status-filter/control-status-filter.component';
import { ControlTypeFilterComponent } from './control-type-filter/control-type-filter.component';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { CustomerAutocompleteComponent } from './customer-autocomplete/customer-autocomplete.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { digitsOnlyDirective } from './directives/digits-only.directive';
import { DocumentDataAddressInputComponent } from './document-data-address-input/document-data-address-input.component';
import { DocumentDataCadastralDesignationInputComponent } from './document-data-cadastral-designation-input/document-data-cadastral-designation-input.component';
import { DocumentDataInputComponent } from './document-data-input/document-data-input.component';
import { DocumentDataLegalPersonInputComponent } from './document-data-legal-person-input/document-data-legal-person-input.component';
import { DocumentDataRegisteredPropertyInputComponent } from './document-data-registered-property-input/document-data-registered-property-input.component';
import { DocumentDataSignatureInputComponent } from './document-data-signature-input/document-data-signature-input.component';
import { FileUploadService } from './file-upload.service';
import { GuarantorAutocompleteComponent } from './guarantor-autocomplete/guarantor-autocomplete.component';
import { IncomeSourceInputComponent } from './income-source-input/income-source-input.component';
import { LenderFilterComponent } from './lender-filter/lender-filter.component';
import { LimitsListComponent } from './limits-list/limits-list.component';
import { ListInputComponent } from './list-input/list-input.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { MaritalstatusInputComponent } from './maritalstatus-input/maritalstatus-input.component';
import { NationalityInputComponent } from './nationality-input/nationality-input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PercentageInputComponent } from './percentage-input/percentage-input.component';
import { RentalContractAutocompleteComponent } from './rental-contract-autocomplete/rental-contract-autocomplete.component';
import { RequiredDocumentStatusFilterComponent } from './required-document-status-filter/required-document-status-filter.component';
import { RequiredDocumentTypeAutocompleteComponent } from './required-document-type-autocomplete/required-document-type-autocomplete.component';
import { TextInputComponent } from './text-input/text-input.component';
import { ToasterComponent } from './toaster/toaster.component';
import { UserAutocompleteComponent } from './user-autocomplete/user-autocomplete.component';
import { DocumentDataGroundLeaseContractInputComponent } from './document-data-ground-lease-contract-input/document-data-ground-lease-contract-input.component';
import { DocumentDataEnergyPerformanceInputComponent } from './document-data-energy-performance-input/document-data-energy-performance-input.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    ActorFilterComponent,
    AddressInputComponent,
    ApplicationPhaseFilterComponent,
    ApplicationPhaseSelectComponent,
    ApprovalStatusFilterComponent,
    AuditListItemComponent,
    BorrowerAutocompleteComponent,
    BuildingAutocompleteComponent,
    CardActions,
    CardComponent,
    CardContent,
    CardFooter,
    CardHeaderComponent,
    CardTitle,
    CheckboxInputComponent,
    ChildDetailHeaderComponent,
    ControlAutocompleteComponent,
    ControlStatusFilterComponent,
    ControlTypeFilterComponent,
    CurrencyInputComponent,
    CustomerAutocompleteComponent,
    DateInputComponent,
    DeleteDialogComponent,
    digitsOnlyDirective,
    DocumentDataAddressInputComponent,
    DocumentDataCadastralDesignationInputComponent,
    DocumentDataInputComponent,
    DocumentDataLegalPersonInputComponent,
    DocumentDataRegisteredPropertyInputComponent,
    DocumentDataSignatureInputComponent,
    GuarantorAutocompleteComponent,
    IncomeSourceInputComponent,
    LenderFilterComponent,
    LimitsListComponent,
    ListInputComponent,
    LoadingButtonComponent,
    MaritalstatusInputComponent,
    NationalityInputComponent,
    NumberInputComponent,
    PercentageInputComponent,
    RentalContractAutocompleteComponent,
    RequiredDocumentStatusFilterComponent,
    RequiredDocumentTypeAutocompleteComponent,
    TextInputComponent,
    ToasterComponent,
    UserAutocompleteComponent,
    DocumentDataGroundLeaseContractInputComponent,
    DocumentDataEnergyPerformanceInputComponent
  ],
  exports: [
    ActorFilterComponent,
    AddressInputComponent,
    ApplicationPhaseFilterComponent,
    ApplicationPhaseSelectComponent,
    ApprovalStatusFilterComponent,
    AuditListItemComponent,
    BorrowerAutocompleteComponent,
    BuildingAutocompleteComponent,
    CardActions,
    CardComponent,
    CardContent,
    CardFooter,
    CardHeaderComponent,
    CardTitle,
    CheckboxInputComponent,
    ChildDetailHeaderComponent,
    ControlAutocompleteComponent,
    ControlStatusFilterComponent,
    ControlTypeFilterComponent,
    CurrencyInputComponent,
    CustomerAutocompleteComponent,
    DateInputComponent,
    DeleteDialogComponent,
    digitsOnlyDirective,
    DocumentDataAddressInputComponent,
    DocumentDataCadastralDesignationInputComponent,
    DocumentDataEnergyPerformanceInputComponent,
    DocumentDataInputComponent,
    DocumentDataLegalPersonInputComponent,
    DocumentDataRegisteredPropertyInputComponent,
    DocumentDataSignatureInputComponent,
    GuarantorAutocompleteComponent,
    IncomeSourceInputComponent,
    LenderFilterComponent,
    LimitsListComponent,
    ListInputComponent,
    LoadingButtonComponent,
    MaritalstatusInputComponent,
    NationalityInputComponent,
    NumberInputComponent,
    PercentageInputComponent,
    RentalContractAutocompleteComponent,
    RequiredDocumentStatusFilterComponent,
    RequiredDocumentTypeAutocompleteComponent,
    TextInputComponent,
    ToasterComponent,
    UserAutocompleteComponent
  ],
  providers: [
    FileUploadService
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        FileUploadService
      ]
    };
  }
}
