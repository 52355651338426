/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Activity } from '../models/activity';
import { Company } from '../models/company';
import { Director } from '../models/director';
import { JsonPatchDocument } from '../models/json-patch-document';
import { Partner } from '../models/partner';
import { Person } from '../models/person';
import { Shareholder } from '../models/shareholder';
import { Subsidiary } from '../models/subsidiary';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCompanyById
   */
  static readonly GetCompanyByIdPath = '/api/v1/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Company> {

    return this.getCompanyById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Company> {

    return this.getCompanyById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * Path part for operation patchCompanyById
   */
  static readonly PatchCompanyByIdPath = '/api/v1/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompanyById$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCompanyById$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PatchCompanyByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompanyById$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCompanyById$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Company> {

    return this.patchCompanyById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompanyById()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCompanyById$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PatchCompanyByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompanyById$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCompanyById(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Company> {

    return this.patchCompanyById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * Path part for operation getCompanyActivitiesById
   */
  static readonly GetCompanyActivitiesByIdPath = '/api/v1/Companies/{id}/Activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyActivitiesById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyActivitiesById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Activity>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyActivitiesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Activity>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyActivitiesById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyActivitiesById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Activity>> {

    return this.getCompanyActivitiesById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Activity>>) => r.body as Array<Activity>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyActivitiesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyActivitiesById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Activity>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyActivitiesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Activity>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyActivitiesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyActivitiesById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Activity>> {

    return this.getCompanyActivitiesById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Activity>>) => r.body as Array<Activity>)
    );
  }

  /**
   * Path part for operation getCompanyDirectorsById
   */
  static readonly GetCompanyDirectorsByIdPath = '/api/v1/Companies/{id}/Directors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyDirectorsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDirectorsById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Director>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyDirectorsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Director>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyDirectorsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDirectorsById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Director>> {

    return this.getCompanyDirectorsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Director>>) => r.body as Array<Director>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyDirectorsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDirectorsById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Director>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyDirectorsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Director>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyDirectorsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyDirectorsById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Director>> {

    return this.getCompanyDirectorsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Director>>) => r.body as Array<Director>)
    );
  }

  /**
   * Path part for operation updateCompanyDirectors
   */
  static readonly UpdateCompanyDirectorsPath = '/api/v1/Companies/{id}/Directors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyDirectors$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyDirectors$Plain$Response(params: {
    id: string;
    body?: Array<Director>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Director>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyDirectorsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Director>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyDirectors$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyDirectors$Plain(params: {
    id: string;
    body?: Array<Director>
  },
  context?: HttpContext

): Observable<Array<Director>> {

    return this.updateCompanyDirectors$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Director>>) => r.body as Array<Director>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyDirectors()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyDirectors$Response(params: {
    id: string;
    body?: Array<Director>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Director>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyDirectorsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Director>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyDirectors$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyDirectors(params: {
    id: string;
    body?: Array<Director>
  },
  context?: HttpContext

): Observable<Array<Director>> {

    return this.updateCompanyDirectors$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Director>>) => r.body as Array<Director>)
    );
  }

  /**
   * Path part for operation addCompanyDirectors
   */
  static readonly AddCompanyDirectorsPath = '/api/v1/Companies/{id}/Directors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyDirectors$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyDirectors$Plain$Response(params: {
    id: string;
    body?: Array<Director>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Director>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyDirectorsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Director>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyDirectors$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyDirectors$Plain(params: {
    id: string;
    body?: Array<Director>
  },
  context?: HttpContext

): Observable<Array<Director>> {

    return this.addCompanyDirectors$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Director>>) => r.body as Array<Director>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyDirectors()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyDirectors$Response(params: {
    id: string;
    body?: Array<Director>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Director>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyDirectorsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Director>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyDirectors$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyDirectors(params: {
    id: string;
    body?: Array<Director>
  },
  context?: HttpContext

): Observable<Array<Director>> {

    return this.addCompanyDirectors$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Director>>) => r.body as Array<Director>)
    );
  }

  /**
   * Path part for operation updateCompanyDirector
   */
  static readonly UpdateCompanyDirectorPath = '/api/v1/Companies/{id}/Directors/{directorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyDirector()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyDirector$Response(params: {
    id: string;
    directorId: string;
    body?: Director
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyDirectorPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('directorId', params.directorId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyDirector$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyDirector(params: {
    id: string;
    directorId: string;
    body?: Director
  },
  context?: HttpContext

): Observable<void> {

    return this.updateCompanyDirector$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteCompanyDirectorById
   */
  static readonly DeleteCompanyDirectorByIdPath = '/api/v1/Companies/{id}/Directors/{directorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyDirectorById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyDirectorById$Response(params: {
    id: string;
    directorId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanyDirectorByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('directorId', params.directorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyDirectorById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyDirectorById(params: {
    id: string;
    directorId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCompanyDirectorById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCompanyPartnersById
   */
  static readonly GetCompanyPartnersByIdPath = '/api/v1/Companies/{id}/Partners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyPartnersById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyPartnersById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Partner>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyPartnersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Partner>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyPartnersById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyPartnersById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Partner>> {

    return this.getCompanyPartnersById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Partner>>) => r.body as Array<Partner>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyPartnersById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyPartnersById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Partner>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyPartnersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Partner>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyPartnersById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyPartnersById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Partner>> {

    return this.getCompanyPartnersById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Partner>>) => r.body as Array<Partner>)
    );
  }

  /**
   * Path part for operation updateCompanyPartners
   */
  static readonly UpdateCompanyPartnersPath = '/api/v1/Companies/{id}/Partners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyPartners$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyPartners$Plain$Response(params: {
    id: string;
    body?: Array<Partner>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Partner>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyPartnersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Partner>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyPartners$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyPartners$Plain(params: {
    id: string;
    body?: Array<Partner>
  },
  context?: HttpContext

): Observable<Array<Partner>> {

    return this.updateCompanyPartners$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Partner>>) => r.body as Array<Partner>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyPartners()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyPartners$Response(params: {
    id: string;
    body?: Array<Partner>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Partner>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyPartnersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Partner>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyPartners$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyPartners(params: {
    id: string;
    body?: Array<Partner>
  },
  context?: HttpContext

): Observable<Array<Partner>> {

    return this.updateCompanyPartners$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Partner>>) => r.body as Array<Partner>)
    );
  }

  /**
   * Path part for operation addCompanyPartners
   */
  static readonly AddCompanyPartnersPath = '/api/v1/Companies/{id}/Partners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyPartners$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyPartners$Plain$Response(params: {
    id: string;
    body?: Array<Partner>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Partner>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyPartnersPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Partner>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyPartners$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyPartners$Plain(params: {
    id: string;
    body?: Array<Partner>
  },
  context?: HttpContext

): Observable<Array<Partner>> {

    return this.addCompanyPartners$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Partner>>) => r.body as Array<Partner>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyPartners()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyPartners$Response(params: {
    id: string;
    body?: Array<Partner>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Partner>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyPartnersPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Partner>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyPartners$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyPartners(params: {
    id: string;
    body?: Array<Partner>
  },
  context?: HttpContext

): Observable<Array<Partner>> {

    return this.addCompanyPartners$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Partner>>) => r.body as Array<Partner>)
    );
  }

  /**
   * Path part for operation deleteCompanyPartnerById
   */
  static readonly DeleteCompanyPartnerByIdPath = '/api/v1/Companies/{id}/Partners/{partnerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyPartnerById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyPartnerById$Response(params: {
    id: string;
    partnerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanyPartnerByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('partnerId', params.partnerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyPartnerById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyPartnerById(params: {
    id: string;
    partnerId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCompanyPartnerById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCompanyShareholdersById
   */
  static readonly GetCompanyShareholdersByIdPath = '/api/v1/Companies/{id}/Shareholders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyShareholdersById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyShareholdersById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyShareholdersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyShareholdersById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyShareholdersById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.getCompanyShareholdersById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyShareholdersById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyShareholdersById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyShareholdersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyShareholdersById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyShareholdersById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.getCompanyShareholdersById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * Path part for operation updateCompanyShareholders
   */
  static readonly UpdateCompanyShareholdersPath = '/api/v1/Companies/{id}/Shareholders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyShareholders$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholders$Plain$Response(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyShareholdersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyShareholders$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholders$Plain(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.updateCompanyShareholders$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyShareholders()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholders$Response(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyShareholdersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyShareholders$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholders(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.updateCompanyShareholders$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * Path part for operation addCompanyShareholders
   */
  static readonly AddCompanyShareholdersPath = '/api/v1/Companies/{id}/Shareholders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyShareholders$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyShareholders$Plain$Response(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyShareholdersPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyShareholders$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyShareholders$Plain(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.addCompanyShareholders$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyShareholders()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyShareholders$Response(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyShareholdersPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyShareholders$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyShareholders(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.addCompanyShareholders$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * Path part for operation updateCompanyShareholder
   */
  static readonly UpdateCompanyShareholderPath = '/api/v1/Companies/{id}/Shareholders/{shareholderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyShareholder()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholder$Response(params: {
    id: string;
    shareholderId: string;
    body?: Shareholder
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyShareholderPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('shareholderId', params.shareholderId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyShareholder$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholder(params: {
    id: string;
    shareholderId: string;
    body?: Shareholder
  },
  context?: HttpContext

): Observable<void> {

    return this.updateCompanyShareholder$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteCompanyShareholderById
   */
  static readonly DeleteCompanyShareholderByIdPath = '/api/v1/Companies/{id}/Shareholders/{shareholderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyShareholderById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyShareholderById$Response(params: {
    id: string;
    shareholderId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanyShareholderByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('shareholderId', params.shareholderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyShareholderById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyShareholderById(params: {
    id: string;
    shareholderId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCompanyShareholderById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCompanySubsidiariesById
   */
  static readonly GetCompanySubsidiariesByIdPath = '/api/v1/Companies/{id}/Subsidiaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanySubsidiariesById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanySubsidiariesById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subsidiary>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanySubsidiariesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subsidiary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanySubsidiariesById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanySubsidiariesById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Subsidiary>> {

    return this.getCompanySubsidiariesById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subsidiary>>) => r.body as Array<Subsidiary>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanySubsidiariesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanySubsidiariesById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subsidiary>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanySubsidiariesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subsidiary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanySubsidiariesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanySubsidiariesById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Subsidiary>> {

    return this.getCompanySubsidiariesById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subsidiary>>) => r.body as Array<Subsidiary>)
    );
  }

  /**
   * Path part for operation updateCompanySubsidiaries
   */
  static readonly UpdateCompanySubsidiariesPath = '/api/v1/Companies/{id}/Subsidiaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanySubsidiaries$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanySubsidiaries$Plain$Response(params: {
    id: string;
    body?: Array<Subsidiary>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subsidiary>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanySubsidiariesPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subsidiary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanySubsidiaries$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanySubsidiaries$Plain(params: {
    id: string;
    body?: Array<Subsidiary>
  },
  context?: HttpContext

): Observable<Array<Subsidiary>> {

    return this.updateCompanySubsidiaries$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subsidiary>>) => r.body as Array<Subsidiary>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanySubsidiaries()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanySubsidiaries$Response(params: {
    id: string;
    body?: Array<Subsidiary>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subsidiary>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanySubsidiariesPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subsidiary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanySubsidiaries$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanySubsidiaries(params: {
    id: string;
    body?: Array<Subsidiary>
  },
  context?: HttpContext

): Observable<Array<Subsidiary>> {

    return this.updateCompanySubsidiaries$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subsidiary>>) => r.body as Array<Subsidiary>)
    );
  }

  /**
   * Path part for operation addCompanySubsidiaries
   */
  static readonly AddCompanySubsidiariesPath = '/api/v1/Companies/{id}/Subsidiaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanySubsidiaries$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanySubsidiaries$Plain$Response(params: {
    id: string;
    body?: Array<Subsidiary>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subsidiary>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanySubsidiariesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subsidiary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanySubsidiaries$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanySubsidiaries$Plain(params: {
    id: string;
    body?: Array<Subsidiary>
  },
  context?: HttpContext

): Observable<Array<Subsidiary>> {

    return this.addCompanySubsidiaries$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subsidiary>>) => r.body as Array<Subsidiary>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanySubsidiaries()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanySubsidiaries$Response(params: {
    id: string;
    body?: Array<Subsidiary>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subsidiary>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanySubsidiariesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subsidiary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanySubsidiaries$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanySubsidiaries(params: {
    id: string;
    body?: Array<Subsidiary>
  },
  context?: HttpContext

): Observable<Array<Subsidiary>> {

    return this.addCompanySubsidiaries$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subsidiary>>) => r.body as Array<Subsidiary>)
    );
  }

  /**
   * Path part for operation updateCompanySubsidiary
   */
  static readonly UpdateCompanySubsidiaryPath = '/api/v1/Companies/{id}/Subsidiaries/{subsidiaryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanySubsidiary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanySubsidiary$Response(params: {
    id: string;
    subsidiaryId: string;
    body?: Subsidiary
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanySubsidiaryPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('subsidiaryId', params.subsidiaryId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanySubsidiary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanySubsidiary(params: {
    id: string;
    subsidiaryId: string;
    body?: Subsidiary
  },
  context?: HttpContext

): Observable<void> {

    return this.updateCompanySubsidiary$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteCompanySubsidiaryById
   */
  static readonly DeleteCompanySubsidiaryByIdPath = '/api/v1/Companies/{id}/Subsidiaries/{subsidiaryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanySubsidiaryById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanySubsidiaryById$Response(params: {
    id: string;
    subsidiaryId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanySubsidiaryByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('subsidiaryId', params.subsidiaryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanySubsidiaryById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanySubsidiaryById(params: {
    id: string;
    subsidiaryId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCompanySubsidiaryById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCompanyUbosById
   */
  static readonly GetCompanyUbosByIdPath = '/api/v1/Companies/{id}/Ubos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyUbosById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUbosById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Person>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyUbosByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Person>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyUbosById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUbosById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Person>> {

    return this.getCompanyUbosById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Person>>) => r.body as Array<Person>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyUbosById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUbosById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Person>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyUbosByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Person>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyUbosById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUbosById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Person>> {

    return this.getCompanyUbosById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Person>>) => r.body as Array<Person>)
    );
  }

  /**
   * Path part for operation updateCompanyUbos
   */
  static readonly UpdateCompanyUbosPath = '/api/v1/Companies/{id}/Ubos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyUbos$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyUbos$Plain$Response(params: {
    id: string;
    body?: Array<Person>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Person>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyUbosPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Person>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyUbos$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyUbos$Plain(params: {
    id: string;
    body?: Array<Person>
  },
  context?: HttpContext

): Observable<Array<Person>> {

    return this.updateCompanyUbos$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Person>>) => r.body as Array<Person>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyUbos()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyUbos$Response(params: {
    id: string;
    body?: Array<Person>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Person>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyUbosPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Person>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyUbos$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyUbos(params: {
    id: string;
    body?: Array<Person>
  },
  context?: HttpContext

): Observable<Array<Person>> {

    return this.updateCompanyUbos$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Person>>) => r.body as Array<Person>)
    );
  }

  /**
   * Path part for operation addCompanyUbos
   */
  static readonly AddCompanyUbosPath = '/api/v1/Companies/{id}/Ubos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyUbos$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyUbos$Plain$Response(params: {
    id: string;
    body?: Array<Person>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Person>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyUbosPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Person>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyUbos$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyUbos$Plain(params: {
    id: string;
    body?: Array<Person>
  },
  context?: HttpContext

): Observable<Array<Person>> {

    return this.addCompanyUbos$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Person>>) => r.body as Array<Person>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyUbos()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyUbos$Response(params: {
    id: string;
    body?: Array<Person>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Person>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyUbosPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Person>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyUbos$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyUbos(params: {
    id: string;
    body?: Array<Person>
  },
  context?: HttpContext

): Observable<Array<Person>> {

    return this.addCompanyUbos$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Person>>) => r.body as Array<Person>)
    );
  }

  /**
   * Path part for operation deleteCompanyUboById
   */
  static readonly DeleteCompanyUboByIdPath = '/api/v1/Companies/{id}/Ubos/{uboId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyUboById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyUboById$Response(params: {
    id: string;
    uboId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanyUboByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('uboId', params.uboId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyUboById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyUboById(params: {
    id: string;
    uboId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCompanyUboById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
