/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Control } from '../models/control';
import { ControlLender } from '../models/control-lender';
import { ControlLenders } from '../models/control-lenders';
import { ControlRevision } from '../models/control-revision';
import { ControlRevisions } from '../models/control-revisions';
import { ControlSource } from '../models/control-source';
import { ControlSources } from '../models/control-sources';
import { Controls } from '../models/controls';
import { IntId } from '../models/int-id';
import { JsonPatchDocument } from '../models/json-patch-document';

@Injectable({
  providedIn: 'root',
})
export class ControlsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getControls
   */
  static readonly GetControlsPath = '/api/v1/Controls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getControls()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControls$Response(params?: {
    lenderIds?: Array<string>;
    active?: boolean;
    types?: Array<'Application' | 'Building' | 'Customer' | 'RegisteredMortgage' | 'RentalContract' | 'Transaction' | 'CadastralDesignation'>;
    terms?: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Controls>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.GetControlsPath, 'get');
    if (params) {
      rb.query('lenderIds', params.lenderIds, {});
      rb.query('active', params.active, {});
      rb.query('types', params.types, {});
      rb.query('terms', params.terms, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Controls>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getControls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControls(params?: {
    lenderIds?: Array<string>;
    active?: boolean;
    types?: Array<'Application' | 'Building' | 'Customer' | 'RegisteredMortgage' | 'RentalContract' | 'Transaction' | 'CadastralDesignation'>;
    terms?: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Controls> {

    return this.getControls$Response(params,context).pipe(
      map((r: StrictHttpResponse<Controls>) => r.body as Controls)
    );
  }

  /**
   * Path part for operation addControl
   */
  static readonly AddControlPath = '/api/v1/Controls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addControl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addControl$Response(params?: {
    body?: Control
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IntId>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.AddControlPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addControl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addControl(params?: {
    body?: Control
  },
  context?: HttpContext

): Observable<IntId> {

    return this.addControl$Response(params,context).pipe(
      map((r: StrictHttpResponse<IntId>) => r.body as IntId)
    );
  }

  /**
   * Path part for operation getControlById
   */
  static readonly GetControlByIdPath = '/api/v1/Controls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getControlById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Control>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.GetControlByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Control>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getControlById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Control> {

    return this.getControlById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Control>) => r.body as Control)
    );
  }

  /**
   * Path part for operation deleteControlById
   */
  static readonly DeleteControlByIdPath = '/api/v1/Controls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteControlById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteControlById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.DeleteControlByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteControlById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteControlById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteControlById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchControl
   */
  static readonly PatchControlPath = '/api/v1/Controls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchControl()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchControl$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Control>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.PatchControlPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Control>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchControl$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchControl(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Control> {

    return this.patchControl$Response(params,context).pipe(
      map((r: StrictHttpResponse<Control>) => r.body as Control)
    );
  }

  /**
   * Path part for operation getControlLenders
   */
  static readonly GetControlLendersPath = '/api/v1/Controls/{id}/Lenders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getControlLenders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlLenders$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlLenders>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.GetControlLendersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlLenders>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getControlLenders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlLenders(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ControlLenders> {

    return this.getControlLenders$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlLenders>) => r.body as ControlLenders)
    );
  }

  /**
   * Path part for operation addControlLender
   */
  static readonly AddControlLenderPath = '/api/v1/Controls/{id}/Lenders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addControlLender()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addControlLender$Response(params: {
    id: string;
    body?: ControlLender
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlLender>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.AddControlLenderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlLender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addControlLender$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addControlLender(params: {
    id: string;
    body?: ControlLender
  },
  context?: HttpContext

): Observable<ControlLender> {

    return this.addControlLender$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlLender>) => r.body as ControlLender)
    );
  }

  /**
   * Path part for operation getControlLender
   */
  static readonly GetControlLenderPath = '/api/v1/Controls/{id}/Lenders/{lenderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getControlLender()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlLender$Response(params: {
    id: string;
    lenderId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlLender>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.GetControlLenderPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('lenderId', params.lenderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlLender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getControlLender$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlLender(params: {
    id: string;
    lenderId: string;
  },
  context?: HttpContext

): Observable<ControlLender> {

    return this.getControlLender$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlLender>) => r.body as ControlLender)
    );
  }

  /**
   * Path part for operation deleteControlLender
   */
  static readonly DeleteControlLenderPath = '/api/v1/Controls/{id}/Lenders/{lenderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteControlLender()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteControlLender$Response(params: {
    id: string;
    lenderId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.DeleteControlLenderPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('lenderId', params.lenderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteControlLender$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteControlLender(params: {
    id: string;
    lenderId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteControlLender$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchControlLender
   */
  static readonly PatchControlLenderPath = '/api/v1/Controls/{id}/Lenders/{lenderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchControlLender()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchControlLender$Response(params: {
    id: string;
    lenderId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlLender>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.PatchControlLenderPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('lenderId', params.lenderId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlLender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchControlLender$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchControlLender(params: {
    id: string;
    lenderId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<ControlLender> {

    return this.patchControlLender$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlLender>) => r.body as ControlLender)
    );
  }

  /**
   * Path part for operation getControlRevisions
   */
  static readonly GetControlRevisionsPath = '/api/v1/Controls/{id}/Revisions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getControlRevisions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlRevisions$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlRevisions>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.GetControlRevisionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlRevisions>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getControlRevisions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlRevisions(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ControlRevisions> {

    return this.getControlRevisions$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlRevisions>) => r.body as ControlRevisions)
    );
  }

  /**
   * Path part for operation addControlRevision
   */
  static readonly AddControlRevisionPath = '/api/v1/Controls/{id}/Revisions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addControlRevision()` instead.
   *
   * This method doesn't expect any request body.
   */
  addControlRevision$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlRevision>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.AddControlRevisionPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlRevision>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addControlRevision$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addControlRevision(params: {
    id: string;
  },
  context?: HttpContext

): Observable<ControlRevision> {

    return this.addControlRevision$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlRevision>) => r.body as ControlRevision)
    );
  }

  /**
   * Path part for operation getControlRevision
   */
  static readonly GetControlRevisionPath = '/api/v1/Controls/{id}/Revisions/{revisionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getControlRevision()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlRevision$Response(params: {
    id: string;
    revisionId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlRevision>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.GetControlRevisionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('revisionId', params.revisionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlRevision>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getControlRevision$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlRevision(params: {
    id: string;
    revisionId: string;
  },
  context?: HttpContext

): Observable<ControlRevision> {

    return this.getControlRevision$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlRevision>) => r.body as ControlRevision)
    );
  }

  /**
   * Path part for operation deleteControlRevision
   */
  static readonly DeleteControlRevisionPath = '/api/v1/Controls/{id}/Revisions/{revisionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteControlRevision()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteControlRevision$Response(params: {
    id: string;
    revisionId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.DeleteControlRevisionPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('revisionId', params.revisionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteControlRevision$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteControlRevision(params: {
    id: string;
    revisionId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteControlRevision$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchControlRevision
   */
  static readonly PatchControlRevisionPath = '/api/v1/Controls/{id}/Revisions/{revisionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchControlRevision()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchControlRevision$Response(params: {
    id: string;
    revisionId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlRevision>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.PatchControlRevisionPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('revisionId', params.revisionId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlRevision>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchControlRevision$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchControlRevision(params: {
    id: string;
    revisionId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<ControlRevision> {

    return this.patchControlRevision$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlRevision>) => r.body as ControlRevision)
    );
  }

  /**
   * Path part for operation getControlRevisionSources
   */
  static readonly GetControlRevisionSourcesPath = '/api/v1/Controls/{id}/Revisions/{revisionId}/Sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getControlRevisionSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlRevisionSources$Response(params: {
    id: string;
    revisionId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlSources>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.GetControlRevisionSourcesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('revisionId', params.revisionId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlSources>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getControlRevisionSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getControlRevisionSources(params: {
    id: string;
    revisionId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ControlSources> {

    return this.getControlRevisionSources$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlSources>) => r.body as ControlSources)
    );
  }

  /**
   * Path part for operation addControlRevisionSource
   */
  static readonly AddControlRevisionSourcePath = '/api/v1/Controls/{id}/Revisions/{revisionId}/Sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addControlRevisionSource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addControlRevisionSource$Response(params: {
    id: string;
    revisionId: string;
    body?: ControlSource
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlSource>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.AddControlRevisionSourcePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('revisionId', params.revisionId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlSource>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addControlRevisionSource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addControlRevisionSource(params: {
    id: string;
    revisionId: string;
    body?: ControlSource
  },
  context?: HttpContext

): Observable<ControlSource> {

    return this.addControlRevisionSource$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlSource>) => r.body as ControlSource)
    );
  }

  /**
   * Path part for operation deleteControlRevisionSource
   */
  static readonly DeleteControlRevisionSourcePath = '/api/v1/Controls/{id}/Revisions/{revisionId}/Sources/{sourceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteControlRevisionSource()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteControlRevisionSource$Response(params: {
    id: string;
    revisionId: string;
    sourceId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.DeleteControlRevisionSourcePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('revisionId', params.revisionId, {});
      rb.path('sourceId', params.sourceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteControlRevisionSource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteControlRevisionSource(params: {
    id: string;
    revisionId: string;
    sourceId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteControlRevisionSource$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchControlRevisionSource
   */
  static readonly PatchControlRevisionSourcePath = '/api/v1/Controls/{id}/Revisions/{revisionId}/Sources/{sourceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchControlRevisionSource()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchControlRevisionSource$Response(params: {
    id: string;
    revisionId: string;
    sourceId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ControlSource>> {

    const rb = new RequestBuilder(this.rootUrl, ControlsService.PatchControlRevisionSourcePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('revisionId', params.revisionId, {});
      rb.path('sourceId', params.sourceId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ControlSource>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchControlRevisionSource$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchControlRevisionSource(params: {
    id: string;
    revisionId: string;
    sourceId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<ControlSource> {

    return this.patchControlRevisionSource$Response(params,context).pipe(
      map((r: StrictHttpResponse<ControlSource>) => r.body as ControlSource)
    );
  }

}
