import { Route, Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';
import { OrganizationResolve } from './organization.resolve';
import { ShellComponent } from './shell.component';
import { UserResolve } from './user.resolve';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';
import { LenderResolve } from './lender.resolve';
import { AuditLogDetailData } from '@app/applications/audit-log-detail/audit-log-detail.component';

/**
 * Provides helper methods to create routes and dialogs.
 */
export class Shell {

  /**
   * Creates routes using the shell component and authentication.
   *
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      resolve: {
        organization: OrganizationResolve,
        user: UserResolve,
        lender: LenderResolve
      },
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true }
    };
  }

  static addDialog<Dialog, Model, Return>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    data: Model): Observable<Return> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    if (data != null) {
      dialogConfig.data = data;
    }
    dialogConfig.panelClass = 'add-dialog';

    return matDialog.open(component, dialogConfig).afterClosed();
  }

  static editDialog<Dialog, Model, Return>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    data: Model,
    config?: (c: MatDialogConfig) => void): Observable<Return> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;
    dialogConfig.panelClass = 'edit-dialog';

    if (config) {
      config(dialogConfig);
    }

    return matDialog.open(component, dialogConfig).afterClosed();
  }

  static informationDialog<Dialog, Model>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    data: Model): Observable<Model> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;
    dialogConfig.panelClass = 'information-dialog';

    return matDialog.open(component, dialogConfig).afterClosed();
  }

  static auditDialog<Dialog>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    data: AuditLogDetailData): Observable<void> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;
    dialogConfig.panelClass = 'audit-dialog';

    return matDialog.open(component, dialogConfig).afterClosed();
  }
}
