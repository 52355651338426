import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';

import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { OrganizationResolve } from './organization.resolve';
import { UserResolve } from './user.resolve';
import { LenderResolve } from './lender.resolve';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    RouterModule
  ],
  declarations: [
    HeaderComponent,
    ShellComponent
  ], providers: [
    OrganizationResolve,
    UserResolve,
    LenderResolve
  ]
})
export class ShellModule { }
